import React from 'react';
import useStyles from './styles';
import TipsIcon from '../../assets/images/more-images/tips.png';
import LeadersIcon from '../../assets/images/more-images/leaders.png';

const MoreCover = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.mainMoreCoverContainer} >
            <div className={classes.moreCover}>
                <div onClick={props.onTips}><img src={TipsIcon} className={classes.footericn} /></div>
                <div onClick={props.onLeaderboard} ><img src={LeadersIcon} className={classes.footericn} /></div>
            </div>
        </div>
    )
}


export default MoreCover