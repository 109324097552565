export const routes = {
  homepage: "/",
  login: "/login",
  home: "/home",
  funtyperunner: "/funtyperunner/:id",
  broadcast: "/broadcast",
  boost: "/boost",
  boostDetails: "/boostDetails",
  gameLoader: "/gameloader",
  scratchcardpage: "/scratchcard",
  scratchwinpage: "/scratchwin",
  tips: "/show",
  profile: "/profile",
  notification: '/notification',
  history: '/history',
  winners: '/winners',
  famers: '/famers',
  leaderboard: '/leaderboard',
  unlock: "/unlock",
  maiar: "/maiar",
  hlLeaders: '/hlLeaders',
  collection: '/collection',
  collectionDetails: '/collectionDetails',

  leaderboards: "/leaderboards",
  postLeaderboard: "/postLeaderboard",
  uploadMedia: "/uploadMedia",
};

export const ids = {
  HofMaxWins: 5,

  FunTypeFamilyFamer: 1,
  FunTypeFamilyTourney: 2,
  FunTypeFamilyHighlight: 3,

  //transactionType
  txnTypeJackpot: 3,
  txnTypeBattleFee: 5,
  txnTypeBattleWin: 6,
  txnTypeBattleDraw: 7,
  txnTypeBattleCancel: 8,
  txnTypeTourneyFee: 9,
  txtTypeWelcomeTreat: 17,
  txnTypeBoughtCharm: 20,
  txnTypeBrandFamer: 21,
  txnTypeBrandHOF: 22,
  txnTypeBrandJackpot: 23,
  txnTypeBrandNineBoxes: 24,

  benggaStoreId: 1,
}