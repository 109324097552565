import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import InnerPageHeader from "../../../../components/InnerPageHeader";
import useStyles from "./styles";

import CryptoJS from "crypto-js";

import SoundCoinWin from "../../../../assets/sounds/coin_win.mp3";
import Lose0 from "../../../../assets/sounds/lose_0.mp3";
import Lose1 from "../../../../assets/sounds/lose_1.mp3";
import Lose2 from "../../../../assets/sounds/lose_2.mp3";

import JackpotTicket from "../../../../assets/images/postloader/JackpotTicket.gif";
import Lose from "../../../../assets/images/postloader/postloader_lose.gif";
import CoinWin from "../../../../assets/images/postloader/postloader_win.gif";

import { image, labels } from "../../../../utils/LangSettings";
import TapWinGiftBoxImage from "../../../../assets/images/GiftBoxImage.png";
import BlueCoinImage from "../../../../assets/images/BlueCoinImage.png";
import BenggaCoinImage from "../../../../assets/images/header-images/Bengga Coin.png";
import ScoreCounter from "../../../../components/ScoreCounter";
import Footer from "../Footer";

import { ids, routes } from "../../../../constants";
import {
  addToJoin,
  addFamer,
  addBrandFamer,
} from "../../../../services/ApiService";

const GameResult = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [gameResult, setGameResult] = useState(false);
  const [tapWin, setTapWin] = useState(false);
  const [prize, setPrize] = useState({ amount: 0, tickets: 0 });
  const [balance, setBalance] = useState({ ticketQuantity: 0, coinAmount: 0 });

  const { engagementData, isWon } = props;
  const COIN_ANIMATION_DELAY = 2800;

  useEffect(() => {
    const balance =
      localStorage.getItem("balance") &&
      JSON.parse(localStorage.getItem("balance"));
    setBalance(balance);
  }, []);

  useEffect(async () => {
    // setTimeout(() => {
    //   setGameResult(true);
    //   setTimeout(() => {
    //     setTapWin(true);
    //   }, 5000);
    // }, 4500);
    await getPrize();
    if (isWon) {
      const balance =
        localStorage.getItem("balance") &&
        JSON.parse(localStorage.getItem("balance"));

      const newBalance = { ...balance };
      newBalance.coinAmount = balance.coinAmount + prize.amount;
      localStorage.setItem("balance", JSON.stringify(newBalance));

      setTimeout(() => {
        setGameResult(true);
        // setTapWin(true); //uncomment for instant scratch
        if (props.difficultyLevel == 5) {
          setTapWin(true);
          _addFamer();
        }
      }, COIN_ANIMATION_DELAY);
    } else {
      setTimeout(() => {
        setGameResult(true);
      }, 2000);
    }
    console.log(`test=${JSON.stringify(engagementData)}`);
  }, []);

  const _addFamer = async () => {
    try {
      const response =
        engagementData.funTypeFamilyId === ids.FunTypeFamilyFamer
          ? await addFamer({ engagementId: engagementData.newId })
          : await addBrandFamer({ engagementId: engagementData.newId });
      console.log("response", response);
    } catch (e) {
      console.log(e);
    }
  };

  const getPrize = async () => {
    var data;
    var prizes;
    if (engagementData.funTypeFamilyId === ids.FunTypeFamilyFamer) {
      data =
        localStorage.getItem("winEnggConsolidated") &&
        JSON.parse(localStorage.getItem("winEnggConsolidated"));
      if (props.difficultyLevel == 1 || props.difficultyLevel == 2) {
        prizes = data.filter((item) => item.win_engg_type == "F0");
      } else {
        prizes = data.filter((item) => item.win_engg_type == "F1");
      }
    } else if (engagementData.funTypeFamilyId === ids.FunTypeFamilyHighlight) {
      data =
        localStorage.getItem("brandWinEnggConsolidated") &&
        JSON.parse(localStorage.getItem("brandWinEnggConsolidated"));
      // console.log(`data=${JSON.stringify(data)}`)
      if (props.difficultyLevel == 1 || props.difficultyLevel == 2) {
        prizes = data.filter(
          (item) =>
            item.win_engg_type == "B0" &&
            item.engagement_id == engagementData.newId
        );
      } else {
        prizes = data.filter(
          (item) =>
            item.win_engg_type == "B1F" &&
            item.engagement_id == engagementData.newId
        );
      }
      // console.log(`prizes=${JSON.stringify(prizes)}`)
    }

    // console.log(`ciphertext=${JSON.stringify(ciphertext)}`)
    // var bytes = CryptoJS.AES.decrypt(ciphertext, process.env.ENCRYPT_KEY);
    // var prizes = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    const index = Math.floor(Math.random() * 101) % prizes.length;
    let prize = prizes[index];
    if (!prize || prize.amount === 0) {
      prize = { amount: 1, tickets: 0 };
    }
    setPrize(prize);
    // console.log(`prizes=${JSON.stringify(prize)}`);

    try {
      const joinData = [
        {
          engagement_id: engagementData.newId,
          engagement_title: engagementData.title,
          record_time: new Date().toISOString().slice(0, -5),
          win_criteria: isWon ? 0 : 1,
          amount: prize.amount,
          tickets: prize.tickets,
          difficulty_level: props.difficultyLevel,
          type:
            engagementData.funTypeFamilyId === ids.FunTypeFamilyFamer ? 1 : 3, //famer - 1; brand famer - 3
        },
      ];
      const response = await addToJoin(joinData);
      console.log("response", response);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    window.onpopstate = () => {
      navigate(routes.home);
    };
  }, []);

  const famerImage = () => {
    console.log("image", image.Level2Image);
    switch (props.difficultyLevel) {
      case 2:
        return image.Level2Image.default;
      case 3:
        return image.Level3Image.default;
      case 4:
        return image.Level4Image.default;
      case 5:
        return image.Level5Image.default;
      default:
        return image.Level1Image.default;
    }
  };

  return (
    <div>
      {isWon && (
        <div className={classes.container}>
          <iframe
            src={SoundCoinWin}
            frameborder="0"
            allow="autoplay; encrypted-media"
            style={{ width: 0, height: 0 }}
            title="video"
          />
          <div style={{ paddingRight: 20 }}>
            <InnerPageHeader
              hasBackBtn
              goBackClick={() => navigate(routes.home)}
              title={`B: ${engagementData.title}`}
            />
          </div>
          <div className={classes.gameResultContent}>
            {engagementData.funTypeFamilyId === ids.FunTypeFamilyFamer && (
              <h3 className={classes.hostedBy}>Hosted by:</h3>
            )}
            <img
              src={engagementData.source.avatarBig}
              alt="favorites logo"
              className={classes.giftBoxSmallImageBox}
            />
            {gameResult === false && (
              <img
                src={CoinWin + "?a=" + Math.random()}
                alt="jackpot ticket"
                className={classes.coinWinImg}
              />
            )}
            {gameResult && (
              <div className={classes.gameResultTextContent}>
                <p className={classes.gameResultWonText}>{labels.YWon}</p>
                <div className={classes.coinImageText}>
                  <div className={classes.coinImageTextRow}>
                    <img
                      src={BenggaCoinImage}
                      alt="bengga coin img"
                      className={classes.coinYellowImage}
                    />
                    <h2 className={classes.coinText}>{prize.amount}</h2>
                  </div>
                  {/* <img className={classes.coinBlueImage} src={BlueCoinImage} alt="coin img" /> */}
                </div>
                <div className={classes.availableProtectCoin}>
                  {/* <p className={classes.availableProtectCoinText}><i>50% {labels.AvailableCoins}</i></p>
                  <p className={classes.availableProtectCoinText}><i>50% {labels.CoinsProtectTomorrow}</i></p> */}
                </div>
                <div className={classes.scoreCounterRow}>
                  {/* <p className={classes.scoreCounterLeftText}>
                    {labels.YNowWon}
                  </p>
                  <ScoreCounter
                    containerStyle={classes.scoreCounterContainer}
                    balance={balance.coinAmount + prize.amount}
                  /> */}
                </div>
                {tapWin === false && (
                  <div className={classes.level5ImageContainer}>
                    <img
                      src={famerImage()}
                      alt="level img"
                      className={classes.level5Image}
                    />
                  </div>
                )}
                {tapWin && (
                  <div className={classes.tapWinContent}>
                    <p className={classes.tapWinContentLeftText}>
                      {labels.TapWin}
                    </p>
                    <Link
                      to={"/scratchcard"}
                      state={{ engagementData: engagementData, balance }}
                    >
                      <img
                        src={TapWinGiftBoxImage}
                        alt="gift box img"
                        className={classes.tapWinContentImg}
                      />
                    </Link>
                    <span className={classes.tapWinContentRightText}>
                      {labels.FBonus}
                    </span>
                  </div>
                )}
              </div>
            )}
            {/* {gameResult === false &&
              <img src={JackpotTicket} alt="jackpot ticket" className={classes.jackpotTicketImg} />
            }
            {gameResult &&
              <div className={classes.gameResultTextContent}>
                <p className={classes.gameResultWonText}>{isWon ? labels.YWon : labels.YLose}</p>
                <h3 className={classes.gameResultTicketTextImg}>
                  <img src={TicketImage} alt="ticket img" className={classes.gameResultCrossTicket} />
                  {prize.tickets} {labels.Tickets}
                </h3>
                <p className={classes.gameResultParagraphTextImg}>
                  {labels.YNowWon}
                  <div className={classes.gameResultTotalTicketTextImg}>
                    <img src={TicketImage} alt="ticket img" className={classes.gameResultFlatTicket} />
                    <span className={classes.gameResultTotalTicketText}>{balance.ticketQuantity + prize.tickets}</span>
                  </div>
                </p>
                {tapWin === false &&
                  <img src={Level5Image} alt="level img" className={classes.level5Image} />
                }
                {tapWin &&
                  <div className={classes.tapWinContent}>
                    <p className={classes.tapWinContentLeftText}>{labels.TapWin}</p>
                    <Link to={'/scratchcard'}
                      state={{ engagementData: engagementData}}
                    >
                      <img src={TapWinGiftBoxImage} alt="gift box img" className={classes.tapWinContentImg} />
                    </Link>
                    <span className={classes.tapWinContentRightText}>{labels.FBonus}</span>
                  </div>
                }
              </div>
            } */}
          </div>
        </div>
      )}
      {!isWon && (
        <div className={classes.container}>
          <iframe
            src={
              Math.random() % 2 == 0
                ? Lose0
                : Math.random() % 2 == 0
                ? Lose1
                : Lose2
            }
            frameborder="0"
            allow="autoplay; encrypted-media"
            style={{ width: 0, height: 0 }}
            title="video"
          />
          <div style={{ paddingRight: 20 }}>
            <InnerPageHeader
              hasBackBtn
              goBackClick={() => navigate(routes.home)}
              title={`B: ${engagementData.title}`}
            />
          </div>
          <div className={classes.gameResultContent}>
            {engagementData.funTypeFamilyId === ids.FunTypeFamilyFamer && (
              <h3 className={classes.hostedBy}>Hosted by:</h3>
            )}
            <img
              src={engagementData.source.avatarBig}
              alt="favorites logo"
              className={classes.giftBoxSmallImageBox}
            />
            {gameResult === false && (
              <img
                src={Lose + "?a=" + Math.random()}
                alt="jackpot ticket"
                className={classes.loseImg}
              />
            )}
            {gameResult && (
              <div className={classes.gameResultLostTextContent}>
                <h3 className={classes.gameResultTicketLoseTextImg}>
                  {labels.YLose}
                </h3>
                <h3 className={classes.gameResultWonText}>
                  Win 5x and try to win
                </h3>
                <img
                  src={engagementData.postloaderImageBig}
                  alt="jackpot ticket"
                  className={classes.postloaderImg}
                />
                <p className={classes.bonus}>
                  {engagementData.funTypeFamilyId === ids.FunTypeFamilyFamer
                    ? `Famer Bonus`
                    : `Grand Prize`}
                </p>
              </div>
            )}
            {tapWin === false && (
              <div className={classes.level5ImageContainer}>
                <img
                  src={famerImage()}
                  alt="level img"
                  className={classes.level5Image}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {!tapWin &&
        (props.difficultyLevel !== 5 ||
          (props.difficultyLevel === 5 && !isWon)) && (
          <Footer
            onRepeat={props.onRepeat}
            showTips={() =>
              navigate("/show", {
                state: {
                  title: engagementData.title,
                  image: engagementData.tipsImageBig,
                },
              })
            }
          />
        )}
    </div>
  );
};

export default GameResult;
