import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InnerPageHeader from "../../../../components/InnerPageHeader";
import useStyles from "./styles";
import GiftBoxImage from "../../../../assets/images/funtype-images/giftBoxImg.png";
import { routes } from "../../../../constants";
import Logo from '../../../../assets/images/logo/famers.png'

const GameLoader = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  let i = 0;
  const [progress, setProgress] = useState(0);
  // const props = window.state;
  const props = useLocation().state;
  useEffect(() => {
    for (let index = 1; index <= 100; index++) {
      setTimeout(() => {
        setProgress(index);
        if (index === 100) {
          // var timer = setTimeout(() => {
          //   navigate(routes.funtyperunner, { state: props });
          // }, 3500);
          // i++;
          // return () => {
          //   clearTimeout(timer)
          // }
        }
      }, 1000);
    }
  }, []);

  return (
    <>
      <div className={classes.container}>
        <div style={{ paddingLeft: -20, paddingRight: 20 }}>
          <InnerPageHeader
            hasBackBtn
            // hasSoundBtn
            goBackClick={() => {
              navigate(-1);
            }}
            // onSoundPress={(toggleSound) => {
            //   props.onSoundPress(toggleSound)
            // }}
            title={`B: ${props.data.title}`}
          />
        </div>
        <div className={classes.giftBoxContent}>
          <div className={classes.giftBoxSmallImageBox}>
            <img src={Logo} className={classes.giftBoxSmallImage} />
            {/* <svg
              className={classes.giftBoxSmallImage}
              version="1.2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 85 134"
              width="85"
              height="85"
            >
              <g id="Folder 1">
                <path fill="#27a5a4" d="m85 0v134l-43-32l-42 32v-134z" />
                <path
                  fill="#fee915"
                  d="m63.5 57.1q1.5 0.3 1.5 1.3q0 0.7-0.7 1.4l-9.8 10l2.3 14.2q0 0.2 0 0.6q0 0.6-0.3 1q-0.2 0.4-0.8 0.4q-0.5 0-1.1-0.3l-12.1-6.7l-12.1 6.7q-0.6 0.3-1.1 0.3q-0.6 0-0.9-0.4q-0.3-0.4-0.3-1q0-0.2 0.1-0.6l2.3-14.2l-9.8-10q-0.7-0.8-0.7-1.4q0-1 1.5-1.3l13.6-2l6.1-12.9q0.5-1.2 1.3-1.2q0.8 0 1.3 1.2l6.1 12.9l13.6 2zm-50.5-46.1c0-2.2 1.8-4 4-4c2.2 0 4 1.8 4 4v13c0 2.2-1.8 4-4 4c-2.2 0-4-1.8-4-4zm17 0c0-2.2 1.8-4 4-4c2.2 0 4 1.8 4 4v13c0 2.2-1.8 4-4 4c-2.2 0-4-1.8-4-4zm17 0c0-2.2 1.8-4 4-4c2.2 0 4 1.8 4 4v13c0 2.2-1.8 4-4 4c-2.2 0-4-1.8-4-4zm17 0c0-2.2 1.8-4 4-4c2.2 0 4 1.8 4 4v13c0 2.2-1.8 4-4 4c-2.2 0-4-1.8-4-4z"
                />
              </g>
            </svg> */}
          </div>
          <div className={classes.giftBoxImageVideoContent}>
            <img
              src={props.data.preloaderImageBig}
              alt="gift box"
              className={classes.giftBoxImage}
            />
          </div>
        </div>
        <div className={classes.customProgress} style={{width: "75%", left: "12.5%"}}>
          <div
            className={classes.customProgressBar}
            style={{ width: +progress + "%" }}
          ></div>
          <span className={classes.customProgressText}>{progress}%</span>
        </div>
      </div>
    </>
  );
};

export default GameLoader;
