import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import useStyles from './styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import GameImage from '../../assets/images/game.jpeg';
import useWindowDimensions from '../../utils/windowDimention';
import Loader from "../../components/Common/Loader";
import InnerPageHeader from '../../components/InnerPageHeader';

import {
  getBroadcasts
} from "../../services/ApiService";

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Broadcast = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getBroadcasts();
      // console.log(`response=${JSON.stringify(response)}`)
      if (response) {
        setData(response.data);
        setLoader(false);
      }

      // console.log("response", response);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  return (
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      <div className={classes.homeContainer}>
        <div style={{ width: '100%', alignItems: 'center', maxWidth: 480 }}>
          <Carousel
            emulateTouch={true}
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            axis={'vertical'}
            showIndicators={false}
            dynamicHeight={true}
            onChange={(item) => setSelectedSlide(item)}
          >
            {data.length > 0 && data.map((item,index) => 
              (<div style={{ height: height }} key={item.broadcastId}>
                {selectedSlide !== index ?
                <div className={classes.homeContentLandscape} /> :
                <video
                  muted={true}
                  playsInline
                  preload="auto"
                  src={item.linkAddress}
                  className={classes.homeContent}
                  loop
                  playsinline
                  autoPlay
                  controls
                  poster={item.imageBigUrl}
                  alt="icon"
                />}
              </div>)
            )
            }
            {/* <div style={{ height: height }}>
              <img className={classes.homeContent} src={GameImage} />
            </div>
            <div style={{ height: height }}>
              <img className={classes.homeContent} src={GameImage} />
            </div> */}
          </Carousel>
          <div className={classes.sliderIndicatorContainer}>
            {data.length > 1 && data.map((slide, index) => (
              <div
                key={`item${index}`}
                style={{
                  width: selectedSlide === index ? 10 : 7,
                  height: selectedSlide === index ? 10 : 7,
                  background: selectedSlide === index ? '#CD49B8' : 'transparent',
                }}
                className={classes.sliderIndicator}
                onClick={() => setSelectedSlide(index)}
              />
            ))}
          </div>
        </div>
        <div style={{position: 'absolute', top: 0, paddingLeft: 20, paddingRight: 20, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
          <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="Broadcast" style={{position: 'absolute'}}/>
        </div>
      </div>
    </div>
  );
};

export default Broadcast;
