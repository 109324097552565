import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useStyles from "./styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useWindowDimensions from "../../utils/windowDimention";
import Loader from "../../components/Common/Loader";
import InnerPageHeader from "../../components/InnerPageHeader";
import Placeholder from "../../assets/images/common-images/user_placeholder.png";
import Famer from "../../assets/images/logo/famers.png";
import { Grid } from "@mui/material";
import moment from "moment";
import { formatNumberWithComma } from "../../utils/utils";
import { routes } from "../../constants";

import { getFamers } from "../../services/ApiService";

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const MAX_FAMERS = 30;

const Famers = ({ history }) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const props = useLocation().state;
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getFamers({
        engagementId: props.engagementData.newId,
        start: 0,
        limit: MAX_FAMERS,
      });
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response) {
        setData(response.data);
        setLoader(false);
      }

      // console.log("response", response);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.addEventListener("touchstart", touchStart);
    window.addEventListener("touchmove", preventTouch, { passive: false });
    return () => {
      window.removeEventListener("touchstart", touchStart);
      window.removeEventListener("touchmove", preventTouch, {
        passive: false,
      });
    };
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };
    document.addEventListener("touchmove", disablePinchZoom, {
      passive: false,
    });
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom);
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  useEffect(() => {
    window.onpopstate = () => {
      navigate(routes.home)
    };
  }, []);

  return (
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      <div className={classes.homeContainer}>
        <div className={classes.titleContainer}>
          <img className={classes.logo} src={Famer} />
          <p className={classes.title}>HALL OF FAME</p>
          <p className={classes.subtitle}>{`First ${MAX_FAMERS} Famers`}</p>
        </div>
        <div
          style={{
            minWidth: 480,
            justifyContent: "center",
            maxWidth: 480,
            marginTop: "65px",
          }}
        >
          <Grid
            container
            style={{ marginLeft: 60 }}
            rowSpacing={2}
            columnSpacing={-15}
            columns={{ xs: 6, sm: 12, md: 12 }}
          >
            {data.length > 0 &&
              data.map((item, index) => (
                <Grid item xs={2} sm={4} md={4} key={index}>
                  <img
                    src={item.playerAvatar ? item.playerAvatar : Placeholder}
                    className={classes.profileImage}
                  />
                </Grid>
              ))}
          </Grid>
          {/* {data.length > 0 && data.map((item,index) => {
              return (<div className={classes.historyContainer} key={item.lastModifiedDate} >
                <img src={item.playerAvatar ? item.playerAvatar : Placeholder} className={classes.profileImage} />
              </div>)}
            )
            } */}
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            paddingLeft: 20,
            paddingRight: 40,
            backgroundColor: "black",
            alignItems: "center",
            width: "100%",
          }}
        >
          <InnerPageHeader
            hasBackBtn
            goBackClick={() => navigate(-1)}
            title={`B: ${props.engagementData.title}`}
            style={{ position: "absolute" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Famers;
