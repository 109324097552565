import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import useStyles from "./styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ComponentData from "../../components/ComponentData";
import GameImage from "../../assets/images/game.jpeg";
import useWindowDimensions from "../../utils/windowDimention";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CryptoJS from "crypto-js";
import MoreFooter from "./MoreFooter";
import TourneyCover from "./TourneyCover";
import HLCover from "./HLCover";
import Lock from "../../assets/images/common-images/lock.png";

import TutorialFamer from "../../assets/images/tutorial/famer.png";

import Tips from "../../assets/images/more-images/tips.png";
import Prizes from "../../assets/images/postloader/prizes.png";
import Leaders from "../../assets/images/more-images/leaders.png";
import MaxJoinFamer from "../../assets/images/common-images/max_join_famer.png";
import MaxJoinHL from "../../assets/images/common-images/max_join_highlight.png";

import { routes, ids } from "../../constants";
import Loader from "../../components/Common/Loader";
import { nftHelper } from "../../helpers/nftHelper";
import { effortHelper } from "../../helpers/effortHelper";

import {
  getFeatured,
  getWinEnggConsolidated,
  getUserProfile,
  getOneTimePay,
  getBrandWinEnggConsolidated,
  getJoinSummary,
  getNotificationCount,
  updateClass,
  checkUserVersion,
  // getSalesPromo
} from "../../services/ApiService";
import MoreCover from "./MoreCover";
import { closeTab } from "../../services/closeTab";

import "./style.css";
import { checkNft } from "../../utils/utils";
import { isAndroid, isIOS, isMobile } from "react-device-detect";

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Home = () => {
  var history = useNavigate();
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [featured, setFeatured] = useState({});
  const [famer, setFamer] = useState();
  const [jackpot, setJackpot] = useState();
  const [highlight, setHighlight] = useState();
  const [tourney, setTourney] = useState();
  const [footerIndex, setFooterIndex] = useState(1);
  const [data, setData] = useState();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [showTutorial, setShowTutorial] = useState(false);
  const [tutorialImage, setTutorialImage] = useState();
  const [showMoreCover, setShowMoreCover] = useState(false);
  const [userData, setUserData] = useState({ avatarSmall: "" });
  const [showTourneyCover, setShowTourneyCover] = useState(false);
  const [showHLCover, setShowHLCover] = useState(false);
  const [oneTimePayData, setOneTimePayData] = useState();
  const [isSoundMuted, setIsSoundMuted] = useState(false);
  const [joinSummary, setJoinSummary] = useState([]);
  const [showMaxJoinFamer, setShowMaxJoinFamer] = useState(false);
  const [showMaxJoinHL, setShowMaxJoinHL] = useState(false);
  const [notifCount, setNotifCount] = useState(0);

  // const [isHLPaid, setIsHLPaid] = useState(false);

  // const [tabClose, setTabClose] = useState(false);
  const user =
    localStorage.getItem("token") && JSON.parse(localStorage.getItem("token"));

  // !tabClose && closeTab("home", window, tabClose, setTabClose, history);

  useEffect(() => {
    // console.log("con");
    if (!user) {
      navigate(routes.login);
    }
  }, [user]);

  // useEffect(() => {
  //   if (!user) {
  //     setLoader(true);
  //   }
  //   else {
  //     setLoader(false);
  //   }
  // }, [user]);

  const soundMuted = localStorage.getItem("gameSound") == 1 ? true : false;
  const footerIdx = localStorage.getItem("footerIndex");
  const itemIdx = localStorage.getItem("itemIndex");

  useEffect(() => {
    fetchUserData();
    fetchNotifCount();
    dataSetup();
    fetchGetSked();
    fetchGetWin();
    logUser();
  }, []);

  const logUser = async() => {
    try {
      setLoader(true);
      const response = await checkUserVersion({ platform: isIOS ? 'ios' : isAndroid ? 'android' : !isMobile ? 'desktop' : 'others', version: parseInt(process.env.REACT_APP_VERSION.replace('.','')) });
      if (response) {
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(`login=${e}`)
    }
  }

  useEffect(() => {
    setIsSoundMuted(soundMuted);
    setFooterIndex(footerIdx);
    footerChanged(footerIdx);
    // console.log(`itemIdx=${itemIdx}`);
  }, []);

  // const fetchSalesPromoData = async (storeItemId) => {
  //   try {
  //     const response = await getSalesPromo({storeItemId});
  //     // console.log(`response=${JSON.stringify(response)}`)
  //     if (typeof response.data.data !== 'undefined' && response.data.data.length > 0) {
  //       if(response.data.data[0].bought && response.data.data[0].bought > 0) {
  //         setIsHLPaid(true);
  //         // console.log(`response.data.data[0]=${JSON.stringify(response.data.data)}`)
  //       }
  //       else {
  //         setIsHLPaid(false);
  //       }
  //     }
  //     else {
  //       setIsHLPaid(false);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const loadFamers = (data) => {
    let famerArr = data.map((item) => {
      var newItem = { ...item.engagement };
      // const images = this.state.engagementImages.filter(i => i.engagementId == newItem.id)
      // newItem.preloaderImageBig = (images.length > 0 && typeof images[0].preloaderImageBig !== 'undefined') ? images[0].preloaderImageBig : item.preloaderImageBig;
      // newItem.postloaderImageBig = (images.length > 0 && typeof images[0].postloaderImageBig !== 'undefined') ? images[0].postloaderImageBig : item.postloaderImageBig;
      newItem.preloaderImageBig = item.preloaderImageBig;
      newItem.postloaderImageBig = item.postloaderImageBig;
      newItem.funTypeFamilyId = ids.FunTypeFamilyFamer;
      newItem.tutorialUrl = item.tutorialUrl;
      if (!isNaN(item.engagement.id)) {
        newItem.newId = item.engagement.id;
      }
      let funType = { ...newItem.funType };
      funType.id = `${funType.id}`;
      newItem.funType = { ...funType };
      newItem.id = `${item.engagement.id}`;
      return newItem;
    });
    let newFamerArr = [];

    //Arranging the cache url for famer videos
    for (let i = 0; i < famerArr.length; i++) {
      let dict = {
        ...famerArr[i],
        gameTitle: famerArr[i].title,
        backgroundImage: famerArr[i].imageBig,
        play: false,
      };
      newFamerArr = newFamerArr.concat([dict]);
    }

    newFamerArr = [];
    for (let i = 0; i < famerArr.length; i++) {
      // let videoUrl = await cacheVideo(famerArr[i].video)
      let dict = {
        ...famerArr[i],
        url: famerArr[i].video,
        gameTitle: famerArr[i].title,
        backgroundImage: famerArr[i].imageBig,
        play: false,
      };
      newFamerArr = newFamerArr.concat([dict]);
    }
    // this.setState({famerArr: newFamerArr}, () => {
    //     //For The first Halt and play Load
    //     if (this.timer) {
    //         clearTimeout(this.timeout)
    //     }
    //     this.timer = setTimeout(() => {
    //         let arr = this.state.famerArr
    //         if (arr.length > 0) {
    //             arr[0].play = true
    //         }
    //         this.setState({ famerArr: arr })
    //     }, 1000);
    // });
    // navigate(routes.leaderboards, { state: newFamerArr });
    setFamer(newFamerArr);

    if (footerIdx == 0) {
      setData(newFamerArr);
      console.log(`itemIdx=${itemIdx}`);
      setSelectedSlide(itemIdx);
    }
  };
  // useEffect(() => {
  // const bc = new BroadcastChannel("Bengga");
  // bc.postMessage("iMonk");
  // }, []);

  const loadJackpot = (data) => {
    let jackpotArr = data.map((item) => {
      var newItem = { ...item.engagement };
      // const images = this.state.engagementImages.filter(i => i.engagementId == newItem.id)
      // newItem.preloaderImageBig = (images.length > 0 && typeof images[0].preloaderImageBig !== 'undefined') ? images[0].preloaderImageBig : item.preloaderImageBig;
      // newItem.postloaderImageBig = (images.length > 0 && typeof images[0].postloaderImageBig !== 'undefined') ? images[0].postloaderImageBig : item.postloaderImageBig;
      newItem.preloaderImageBig = item.preloaderImageBig;
      newItem.postloaderImageBig = item.postloaderImageBig;
      if (!isNaN(item.engagement.id)) {
        newItem.newId = item.engagement.id;
      }
      let funType = { ...newItem.funType };
      funType.id = `${funType.id}`;
      newItem.funType = { ...funType };
      newItem.id = `${item.engagement.id}`;
      return newItem;
    });
    //Arranging the cache url for tourney videos
    let newJackpotArr = [];
    //Arranging the cache url for jackpot videos
    for (let i = 0; i < jackpotArr.length; i++) {
      let dict = {
        ...jackpotArr[i],
        gameTitle: jackpotArr[i].title,
        backgroundImage: jackpotArr[i].imageBig,
        play: false,
      };
      newJackpotArr = newJackpotArr.concat([dict]);
    }

    // newJackpotArr = [];
    // for (let i = 0; i < jackpotArr.length; i++) {
    //     // let videoUrl = await cacheVideo(jackpotArr[i].video)
    //     let dict = { ...jackpotArr[i], url: jackpotArr[i].video, gameTitle: jackpotArr[i].title, backgroundImage: jackpotArr[i].imageBig, play: false }
    //     newJackpotArr = newJackpotArr.concat([dict])
    // }
    // navigate(routes.leaderboards, { state: newJackpotArr });
    setJackpot(newJackpotArr);
  };

  const loadHighlight = (data) => {
    let hlArr = data.map((item) => {
      var newItem = { ...item.engagement };
      // const images = this.state.engagementImages.filter(i => i.engagementId == newItem.id)
      // newItem.preloaderImageBig = (images.length > 0 && typeof images[0].preloaderImageBig !== 'undefined') ? images[0].preloaderImageBig : item.preloaderImageBig;
      // newItem.postloaderImageBig = (images.length > 0 && typeof images[0].postloaderImageBig !== 'undefined') ? images[0].postloaderImageBig : item.postloaderImageBig;
      newItem.preloaderImageBig = item.preloaderImageBig;
      newItem.postloaderImageBig = item.postloaderImageBig;
      // newItem.entryFeeImageBig = item.entryFeeImageBig;
      newItem.tutorialUrl = item.tutorialUrl;
      newItem.funTypeFamilyId = ids.FunTypeFamilyHighlight;
      if (!isNaN(item.engagement.id)) {
        newItem.newId = item.engagement.id;
      }
      newItem.joinType = item.joinType;
      newItem.joinErrorMessage = item.joinErrorMessage;
      newItem.joinRequirement = item.joinRequirement;
      newItem.gameType = item.gameType;
      newItem.joinFee = item.joinFee;
      let funType = { ...newItem.funType };
      funType.id = `${funType.id}`;
      newItem.funType = { ...funType };
      newItem.id = `${item.engagement.id}`;
      return newItem;
    });
    let newHLArr = [];

    //Arranging the cache url for famer videos
    for (let i = 0; i < hlArr.length; i++) {
      let dict = {
        ...hlArr[i],
        gameTitle: hlArr[i].title,
        backgroundImage: hlArr[i].imageBig,
        play: false,
      };
      newHLArr = newHLArr.concat([dict]);
    }

    newHLArr = [];
    for (let i = 0; i < hlArr.length; i++) {
      // let videoUrl = await cacheVideo(hlArr[i].video)
      let dict = {
        ...hlArr[i],
        url: hlArr[i].video,
        gameTitle: hlArr[i].title,
        backgroundImage: hlArr[i].imageBig,
        play: false,
      };
      newHLArr = newHLArr.concat([dict]);
    }

    // console.log(`arrDataPrev=${JSON.stringify(newHLArr)}`)
    // this.setState({origBrandArr: [...newHLArr]}, () => {
    //     this._reloadBrandsData();
    // })
    // // this._reloadBrandsData(newHLArr);

    // // this.setState({brandsArr: newHLArr});
    setHighlight(newHLArr);
    if (footerIdx == 1) {
      setData(newHLArr);
      setSelectedSlide(itemIdx);
    }
  };

  const loadTourney = (data) => {
    const TOURNAMENT_FTF_ID = "D6sLlOOTEea_Af5VE1A08w";
    let tourneyArr = data.map((item) => {
      var newItem = { ...item.engagement };
      // const images = this.state.engagementImages.filter(i => i.engagementId == newItem.id)
      // newItem.preloaderImageBig = (images.length > 0 && typeof images[0].preloaderImageBig !== 'undefined') ? images[0].preloaderImageBig : item.preloaderImageBig;
      // newItem.postloaderImageBig = (images.length > 0 && typeof images[0].postloaderImageBig !== 'undefined') ? images[0].postloaderImageBig : item.postloaderImageBig;
      // for paid tourney use /////////////
      newItem.funTypeFamilyId = ids.FunTypeFamilyTourney;
      newItem.preloaderImageBig = item.preloaderImageBig;
      newItem.postloaderImageBig = item.postloaderImageBig;
      newItem.joinType = item.joinType;
      newItem.isSpecial = item.isSpecial;
      newItem.targetScore = item.targetScore;
      newItem.topPlayers = item.topPlayers;
      newItem.joinFee = item.joinFee;
      newItem.joinTicket = item.joinTicket;
      newItem.joinHour = item.joinHour;
      newItem.joinFeeType = item.joinFeeType;
      newItem.potMoney = item.potMoney;
      newItem.potMoneyAsOf = item.potMoneyAsOf;
      newItem.userCap = item.userCap;
      newItem.engagementType = item.engagementType;
      newItem.tutorialUrl = item.tutorialUrl;
      /////////////////////////////////////
      let funType = { ...newItem.funType };
      let family = { ...funType.family };
      funType.id = `${funType.id}`;
      let oldId;
      family.id = TOURNAMENT_FTF_ID;
      funType.family = { ...family };
      newItem.funType = { ...funType };
      if (!isNaN(item.engagement.id)) {
        newItem.newId = item.engagement.id;
      }
      newItem.id = `${item.engagement.id}`;
      return newItem;
    });
    //Arranging the cache url for tourney videos
    let newTourneyArr = [];
    for (let i = 0; i < tourneyArr.length; i++) {
      let dict = {
        ...tourneyArr[i],
        gameTitle: tourneyArr[i].title,
        backgroundImage: tourneyArr[i].imageBig,
        play: false,
      };
      newTourneyArr = newTourneyArr.concat([dict]);
    }

    // newTourneyArr = [];
    // for (let i = 0; i < tourneyArr.length; i++) {
    //     // let videoUrl = await cacheVideo(tourneyArr[i].video)
    //     let dict = { ...tourneyArr[i], url: tourneyArr[i].video, gameTitle: tourneyArr[i].title, backgroundImage: tourneyArr[i].imageBig, play: false }
    //     newTourneyArr = newTourneyArr.concat([dict])
    // }
    setTourney(newTourneyArr);
    if (footerIdx == 2) {
      setData(newTourneyArr);
    }
  };

  const fetchGetWin = async () => {
    try {
      setLoader(true);
      const response = await getJoinSummary();
      if (response) {
        setJoinSummary(response.data.data);
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const fetchGetSked = async () => {
    try {
      setLoader(true);
      const response = await getFeatured();

      if (response) {
        const famers = response.data.filter((item) => {
          return item.funTypeFamily.id == 1;
        });
        if (famers.length > 0) {
          loadFamers(famers[0].items);
        }
        // const jackpots = response.data.filter((item) => {
        //   return item.funTypeFamily.id == 2;
        // });
        // if (jackpots.length > 0) {
        //   loadJackpot(jackpots[0].items);
        // }
        const highlights = response.data.filter((item) => {
          return item.funTypeFamily.id == 6;
        });
        if (highlights.length > 0) {
          loadHighlight(highlights[0].items);
        }
        const tourneys = response.data.filter((item) => {
          return item.funTypeFamily.id == 4;
        });
        if (tourneys.length > 0) {
          loadTourney(tourneys[0].items);
        }
        setLoader(false);
      }
      // console.log("response", response);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    window.addEventListener("touchstart", touchStart);
    window.addEventListener("touchmove", preventTouch, { passive: false });
    return () => {
      window.removeEventListener("touchstart", touchStart);
      window.removeEventListener("touchmove", preventTouch, {
        passive: false,
      });
    };
  }, []);

  const dataSetup = async () => {
    try {
      const response = await getWinEnggConsolidated({});
      if (response) {
        // console.log("getWinEnggConsolidated Response => ", response.data);
        // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.ENCRYPT_KEY).toString();
        // //log encrypted data
        // console.log('Encrypt Data -')
        // console.log(ciphertext);
        localStorage.setItem(
          "winEnggConsolidated",
          JSON.stringify(response.data)
        );
      }
      const response1 = await getBrandWinEnggConsolidated({});
      if (response1) {
        // console.log("getBrandWinEnggConsolidated Response => ", response1.data);
        // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.ENCRYPT_KEY).toString();
        // //log encrypted data
        // console.log('Encrypt Data -')
        // console.log(ciphertext);
        localStorage.setItem(
          "brandWinEnggConsolidated",
          JSON.stringify(response1.data)
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const fetchOneTimePay = async () => {
    try {
      setLoader(true);
      const response = await getOneTimePay();
      if (response) {
        setOneTimePayData(response.data.data);
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const footerChanged = (index) => {
    setData([]);
    setFooterIndex(index);
    localStorage.setItem("footerIndex", index);
    // console.log(`footerChanged.footerindex=${localStorage.getItem("footerIndex")}`);
    setSelectedSlide(0);
    if (footerIdx != index) {
      localStorage.setItem("itemIndex", 0);
    }
    setShowHLCover(false);
    setShowTourneyCover(false);
    switch (index) {
      case 1:
        //   setData(jackpot);
        //   break;
        // case 2:
        setData(highlight);
        // fetchSalesPromoData((JSON.parse(highlight[0].joinRequirement) || []).filter(j => j.type == 2)[0].storeItemId)
        // fetchOneTimePay();
        // fetchSalesPromoData();
        break;
      case 2:
        setData(tourney);
        fetchOneTimePay();
        break;
      default:
        setData(famer);
        break;
    }
    fetchUserData();
    computeClass();
  };

  const logoutHandler = () => {
    setLoader(false);

    localStorage.removeItem("token");
    localStorage.removeItem("name");
    navigate(routes.login);
  };

  const broadcastHandler = () => {
    setLoader(false);
    navigate(routes.broadcast);
  };

  const boostHandler = () => {
    setLoader(false);
    navigate(routes.boost);
  };

  const notificationHandler = () => {
    setLoader(false);
    navigate(routes.notification);
  };

  const historyHandler = () => {
    setLoader(false);
    navigate(routes.history, {state: { userData: userData} });
  };

  const collectionHandler = () => {
    setLoader(false);
    navigate(routes.collection, {state: { userData: userData} });
  };

  const sidebarIndexClicked = (index) => {
    switch (index) {
      case 0:
        notificationHandler();
        break;
      case 1:
        boostHandler();
        break;
      case 2:
        broadcastHandler();
        break;
      case 3:
        collectionHandler();
        break;
      case 4:
        historyHandler();
        break;
      case 5:
        setIsSoundMuted(!isSoundMuted);
        localStorage.setItem("gameSound", isSoundMuted ? 0 : 1);
        console.log(
          `localStorage.getItem=${localStorage.getItem("gameSound")}`
        );
        break;
      case 6:
        logoutHandler();
        break;

      default:
    }
  };

  const _onShowTips = (engagementData) => {
    setShowMoreCover(false);
    navigate(routes.tips, {
      state: {
        title: engagementData.title,
        image: engagementData.tipsImageBig,
      },
    });
  };

  const _onShowTutorial = (engagementData) => {
    setShowMoreCover(false);
    navigate(routes.tips, {
      state: { title: engagementData.title, image: engagementData.tutorialUrl },
    });
  };

  const _onShowLeaderboard = (engagementData) => {
    setShowMoreCover(false);
    if (engagementData.funTypeFamilyId === ids.FunTypeFamilyFamer) {
      navigate(routes.famers, { state: { engagementData: engagementData } });
    } else if (engagementData.funTypeFamilyId === ids.FunTypeFamilyHighlight) {
      navigate(routes.hlLeaders, { state: { engagementData: engagementData } });
    } else if (engagementData.funTypeFamilyId === ids.FunTypeFamilyTourney) {
      navigate(routes.leaderboard, {
        state: { engagementData: engagementData },
      });
    }
  };

  const _onTutorial = (show, item) => {
    if (footerIndex == 0) {
      //famer
      setTutorialImage(TutorialFamer);
    }
    setShowTutorial(show);
  };

  const _onMore = (show) => {
    setShowMoreCover(show);
  };

  const profileClicked = () => {
    setSidebarOpen(false);
    navigate(routes.profile);
  };

  const fetchUserData = async () => {
    try {
      setLoader(true);
      const response = await getUserProfile();
      // console.log(`response=${JSON.stringify(response)}`)
      if (response) {
        setUserData(response.data.data);
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  let hasNft = false;

  const computeClass = async () => {
    console.log(`compute class`)
    const address = localStorage.getItem("maiarAddress");
    const creators = [ //check in maiar page
      "erd1qf9et8d4swd09unnv0rdzezpkyxg5f2tk00tlr039nhjyz8xrm5qfj53ww", //elva
      "erd1jpr49da65vae0wjl73e3ghv443swxqaqvacu2f6uelvcnuxxw5jqktqsyh", //elrond lion
      "erd1qqqqqqqqqqqqqpgqeys5lt360l342m4dk2edehykktwn34djys5s46rk5f", //evoluzion
      "erd14edl4fvr8wc2sczrz5af6tfmfucgqzsh4u73hnxw96l07cekdg6schwtsh", //maiarpunks
      "erd1qqqqqqqqqqqqqpgqf6qde2f9dt8u943la9ehgvemzldx7rccxjhs3nqucu", //superrarebears
      "erd18e7t3fquqjm9rs6385tqu0pk543e43e44rlkrhtdzfd64rgu7jrq6jrza9", //whaley
    ];
    
    let classId, difficultyClassId;
    const EFFORT_CHECK = 1000;
    const effort = await effortHelper({
      user_wallet: address,
    });
    hasNft = false;
    creators.every(async (creator) => {
      const data = {
        user_wallet: address,
        creator_wallet: creator,
      };
      const nft = await nftHelper(data);
      // console.log(`nft=${JSON.stringify(nft)}`)
      if (nft.length > 0) {
        hasNft = true;
        if (hasNft && effort >= EFFORT_CHECK) {
          //class: a, diff: low
          classId = 4;
          difficultyClassId = 1;
        } else if (hasNft && effort < EFFORT_CHECK) {
          //class: b, diff: mid
          classId = 3;
          difficultyClassId = 2;
        }
        const userClass = {
          winEnggClassId: classId,
          difficultyClassId: difficultyClassId,
        };
    
        if (userData && classId > userData.classId) {
          await updateClass(userClass);
        }
      }
      // console.log(`hasNft=${hasNft}; effort=${effort}`)
    });
    // console.log(`hasNft=${hasNft}; effort=${effort}`)
    if (hasNft && effort >= EFFORT_CHECK) {
      //class: a, diff: low
      classId = 4;
      difficultyClassId = 1;
    } else if (hasNft && effort < EFFORT_CHECK) {
      //class: b, diff: mid
      classId = 3;
      difficultyClassId = 2;
    } else if (!hasNft && effort >= EFFORT_CHECK) {
      //class: c, diff: high
      classId = 2;
      difficultyClassId = 3;
    } else {
      //class: d, diff: high
      classId = 1;
      difficultyClassId = 4;
    }
    // console.log(`classId=${classId}; difficultyClassId=${difficultyClassId}`)
    const userClass = {
      winEnggClassId: classId,
      difficultyClassId: difficultyClassId,
    };

    if (userData && classId > userData.classId) {
      await updateClass(userClass);
    }
  }

  const fetchNotifCount = async () => {
    try {
      setLoader(true);
      const response = await getNotificationCount();
      if (response) {
        setNotifCount(response.data.count);
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const onJoin = (index) => {
    var item;
    if (footerIdx == 0) {
      item = famer[index];
    } else if (footerIdx == 1) {
      item = highlight[index];
    } else if (footerIdx == 2) {
      item = tourney[index];
    }
    // window.alert(`item.title=${item.title}`)
    if (new Date(item.joinEndDate) > new Date()) {
      if (item.funTypeFamilyId === ids.FunTypeFamilyFamer) {
        const data = joinSummary.filter((js) => js.engagementId === item.newId);
        if (data.length > 0) {
          if (data[0].wins >= ids.HofMaxWins) {
            setShowMaxJoinFamer(true);
          } else {
            navigate(`/funtyperunner/${item.id}`, {
              state: {
                data: item,
                hasTarget: true,
                isSoundMuted,
                joinSummary: joinSummary.filter(
                  (js) => js.engagementId === item.newId
                ),
                userData,
              },
            });
          }
        } else {
          navigate(`/funtyperunner/${item.id}`, {
            state: {
              data: item,
              hasTarget: true,
              isSoundMuted,
              joinSummary: joinSummary.filter(
                (js) => js.engagementId === item.newId
              ),
              userData,
            },
          });
        }
      } else if (item.funTypeFamilyId === ids.FunTypeFamilyTourney) {
        setShowTourneyCover(true);
      } else if (item.funTypeFamilyId === ids.FunTypeFamilyHighlight) {
        if (item.gameType == 1) {
          const data = joinSummary.filter(
            (js) => js.engagementId === item.newId
          );
          if (data.length > 0) {
            if (data[0].wins >= ids.HofMaxWins) {
              setShowMaxJoinHL(true);
            } else {
              setShowHLCover(true);
            }
          } else {
            setShowHLCover(true);
          }
        } else {
          setShowHLCover(true);
        }
      }
    } else {
      window.location.reload(false);
    }
  };

  return (
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      <div className={classes.homeContainer}>
        <Header
          setSidebarOpen={setSidebarOpen}
          sidebarOpen={sidebarOpen}
          avatar={userData.avatarSmall}
          notificationCount={notifCount}
        />
        {sidebarOpen && (
          <Sidebar
            isSoundMuted={isSoundMuted}
            notificationCount={notifCount}
            avatar={userData.avatarSmall}
            avatarClicked={profileClicked}
            setSidebarOpen={setSidebarOpen}
            sidebarIndexClicked={sidebarIndexClicked}
          />
        )}
        <div style={{ width: "100%", alignItems: "center" }}>
          <Carousel
            transitionTime={100}
            emulateTouch={true}
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={false}
            axis={"vertical"}
            showIndicators={false}
            dynamicHeight={true}
            selectedItem={
              Number(itemIdx) != selectedSlide ? Number(itemIdx) : selectedSlide
            }
            onChange={(item) => {
              setSelectedSlide(item);
              setShowMoreCover(false);
              localStorage.setItem("itemIndex", item);
              // console.log(`footerIndex=${footerIndex}`)
              // if (footerIndex === 1) { //highlight
              //   console.log(`highlight[item].joinRequirement=${(JSON.parse(highlight[item].joinRequirement) || []).filter(j => j.type == 2)[0].storeItemId}`)
              //   await fetchSalesPromoData((JSON.parse(highlight[item].joinRequirement) || []).filter(j => j.type == 2)[0].storeItemId)
              // }
            }}
            // onClickItem={(item) => {
            //   console.log(`item=${JSON.stringify(item)}`)
            //   history('/funtyperunner', {state: item})
            // }}
          >
            {data &&
              data.map((item, index) => (
                <div
                  style={{
                    height: height,
                    width: width,
                    display: "block",
                  }}
                  className={classes.gameSliderImageContent}
                  key={item.newId}
                >
                  <div
                    // to={{ pathname: item.funTypeFamilyId === ids.FunTypeFamilyFamer ? `/funtyperunner/${item.id}` : `/tourneyCover/${item.id}` }}
                    // state={{ data: item, hasTarget: item.funTypeFamilyId !== ids.FunTypeFamilyTourney }}
                    onClick={() => onJoin(index)}
                    style={{ height: height, display: "block" }}
                  >
                    {itemIdx != index && (
                      <img
                        className={classes.homeContent}
                        src={item.imageBig}
                      />
                    )}
                    {itemIdx == index && (
                      <div>
                        <img
                          src={item.imageBig}
                          className={classes.homeContent}
                          style={{ position: "absolute", left: 0 }}
                        />
                        <video
                          src={item.video}
                          playsInline
                          autoPlay
                          loop
                          muted
                          preload="auto"
                          alt="icon"
                          className={classes.homeContent}
                          style={{
                            backgroundColor: "transparent",
                            position: "absolute",
                            left: 0,
                          }}
                          poster={item.imageBig}
                        />
                      </div>
                    )}
                  </div>
                  <div className={classes.hostContainer}>
                    <div
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {item.funTypeFamilyId == ids.FunTypeFamilyFamer && (
                        <p className={classes.hostedBy}>Hosted by:</p>
                      )}
                      <div className={classes.hostImgContainer}>
                        <div className={classes.hostImgContainer2}>
                          <img
                            className={classes.hostImg}
                            src={item.source.avatarBig}
                          />
                        </div>
                      </div>
                      {/* <p className={classes.host}>{item.source.displayName}</p> */}
                    </div>
                  </div>
                  {/* {  item.funTypeFamilyId === ids.FunTypeFamilyHighlight && !isHLPaid && <div className={classes.modalCircle}>
                    <img className={classes.lockImage} src={Lock} alt="lock" />
                  </div>} */}
                  {/* <a
                    style={{ height: height, display: "block" }}
                    onClick={() => {
                      let newWindow = window.open(
                        `/funtyperunner/${item.id}`,
                        "_blank"
                      );
                      newWindow.state = { data: item, hasTarget: true };
                    }}
                  >
                    {selectedSlide !== index && (
                      <img
                        className={classes.homeContent}
                        src={item.imageBig}
                      />
                    )}
                    {selectedSlide === index && (
                      <video
                        src={item.video}
                        autoPlay
                        loop
                        muted
                        alt="icon"
                        className={classes.homeContent}
                        poster={item.imageBig}
                      />
                    )}
                  </a> */}
                  <ComponentData name={item.title} />
                  {new Date(item.joinEndDate) > new Date() && (
                    <MoreFooter
                      leftIcon={Prizes}
                      rightIcon={Leaders}
                      onLeftIconPress={() => _onShowTutorial(item)}
                      onRightIconPress={() => _onShowLeaderboard(item)}
                      // onTutorial={() => _onTutorial(true, item)}
                      // onMore={() => _onMore(!showMoreCover)}
                    />
                  )}
                  {showMoreCover && (
                    <MoreCover
                      className={classes.mainMoreCoverContainer}
                      onTips={() => _onShowTips(item)}
                      onLeaderboard={() => _onShowLeaderboard(item)}
                    />
                  )}
                </div>
              ))}
          </Carousel>
          <div className={classes.sliderIndicatorContainer}>
            {data &&
              data.map((slide, index) => (
                <div
                  key={`item${index}`}
                  style={{
                    width: itemIdx == index ? 10 : 7,
                    height: itemIdx == index ? 10 : 7,
                    background: itemIdx == index ? "#CD49B8" : "transparent",
                  }}
                  className={classes.sliderIndicator}
                  onClick={() => setSelectedSlide(index)}
                />
              ))}
          </div>
        </div>
        {showMaxJoinFamer && (
          <div
            className={classes.tutorialContainer}
            onClick={() => setShowMaxJoinFamer(false)}
          >
            <img src={MaxJoinFamer} className={classes.tutorialImage} />
          </div>
        )}
        {showMaxJoinHL && (
          <div
            className={classes.tutorialContainer}
            onClick={() => setShowMaxJoinHL(false)}
          >
            <img src={MaxJoinHL} className={classes.tutorialImage} />
          </div>
        )}
        {showTutorial && (
          <div
            className={classes.tutorialContainer}
            onClick={() => _onTutorial(false)}
          >
            <img src={tutorialImage} className={classes.tutorialImage} />
          </div>
        )}
        {showTourneyCover && (
          <TourneyCover
            engagementData={tourney[itemIdx]}
            oneTimePayData={oneTimePayData}
            onCancel={() => setShowTourneyCover(false)}
            onJoin={() => {
              const item = tourney[itemIdx];
              navigate(`/funtyperunner/${item.id}`, {
                state: {
                  data: item,
                  hasTarget: false,
                  isSoundMuted,
                  joinSummary: joinSummary.filter(
                    (js) => js.engagementId === item.newId
                  ),
                  userData,
                },
              });
            }}
          />
        )}
        {showHLCover && (
          <HLCover
            engagementData={highlight[itemIdx]}
            onCancel={() => setShowHLCover(false)}
            onJoin={() => {
              const item = highlight[itemIdx];
              navigate(`/funtyperunner/${item.id}`, {
                state: {
                  data: item,
                  hasTarget: item.gameType === 3 ? false : true,
                  isSoundMuted,
                  joinSummary: joinSummary.filter(
                    (js) => js.engagementId === item.newId
                  ),
                  userData,
                },
              });
            }}
          />
        )}
        <Footer setIndex={(index) => footerChanged(index)} index={footerIdx} />
      </div>
    </div>
  );
};

export default Home;
