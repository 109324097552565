import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import InnerPageHeader from '../../components/InnerPageHeader';
import ScratchMe from "react-scratch-me";
import useStyles from './styles';
import ScoreImage from '../../assets/images/header-images/Bengga Coin.png';
import ScratchWinCoinImage from '../../assets/images/coin_fountain.gif';
import Confetti from '../../assets/images/postloader/confetti.gif';
import SoundCoinFountain from '../../assets/sounds/coin_fountain.mp3';
import ConfettiSound from '../../assets/sounds/confetti.mp3';
import FavoritesLogo from '../../assets/images/logo/famers.png';
import BenggaCoinImage from '../../assets/images/postloader/open_box.png';
import ScrachCardFrontImage from '../../assets/images/postloader/scratch_cover.png';

import { labels } from '../../utils/LangSettings';

import { getWinEnggF2, completeWin, getBrandWinEnggB2F, completeBrandWin } from '../../services/ApiService';
import { ids } from '../../constants';

const ScratchCard = () => {
  const props = useLocation().state;
  const classes = useStyles();
  const navigate = useNavigate();
  const [completed, setCompleted] = useState(false);
  const [hofWinData, setHofWinData] = useState({});
  console.log('completed', completed);
  useEffect(async () => {
    let i = 0;
    if (completed === true) {
      if (i === 0) {
        try {
          // if (props.engagementData.funTypeFamilyId === ids.FunTypeFamilyFamer) {
          //   const response = await completeWin({winComparisonId: hofWinData.winComparisonId});
          //   // console.log("response", response);
          // }
          // else if (props.engagementData.funTypeFamilyId === ids.FunTypeFamilyHighlight) {
            const response = await completeBrandWin({winComparisonId: hofWinData.winComparisonId});
            // console.log("response", response);
          // }
        } catch (e) {
          console.log(e);
        }

        setTimeout(() => {
          navigate('/scratchwin', {state: { engagementData: props.engagementData, hofWinData, balance: props.balance }});
        }, 2200);
        i++;
      }
    }
  }, [completed, navigate]);

  useEffect(async () => {
    try {
      console.log(`props.engagementData=${JSON.stringify(props.engagementData)}`)
      // if (props.engagementData.funTypeFamilyId === ids.FunTypeFamilyFamer) {
      //   const response = await  getWinEnggF2({engagementId: props.engagementData.newId})
      //   if (response) {
      //     console.log("getWinEnggF2 Response => ", JSON.stringify(response.data));
      //     setHofWinData(response.data);
      //   }
      // }
      // else if (props.engagementData.funTypeFamilyId === ids.FunTypeFamilyHighlight) {
        const response = await  getBrandWinEnggB2F({engagementId: props.engagementData.newId});
        if (response) {
          console.log("getBrandWinEnggF2 Response => ", JSON.stringify(response.data));
          setHofWinData(response.data);
        }
      // }
    } catch (e) {
      console.log(e);
    }
  }, [])


  return (
    <>
      <div className={classes.container}>
        <InnerPageHeader title={`B: ${props?.engagementData?.title}`} />
        <div className={classes.scratchWinContent}>
          <img src={FavoritesLogo} alt="favorites logo" className={classes.scratchWinSmallImageBox} />
          <span className={classes.scratchWinMuchText}>{completed ? labels.YWon : labels.WinMuch}</span>
          {completed === false &&
            <div className={classes.scratchWinCoinImgText}>
              <img src={ScoreImage} alt="coin img" className={classes.scratchWinCoinImg} />
              <h3 className={classes.scratchWinCoinText}>5,000</h3>
            </div>
          }
          <div className={classes.scratchCardContent}>
            {completed && hofWinData.amount > 0 && <iframe src={SoundCoinFountain}
              frameborder='0'
              allow='autoplay; encrypted-media'
              style={{width: 0, height: 0}}
              title='video'
            />}
            {completed && hofWinData.product && <iframe src={ConfettiSound}
              frameborder='0'
              allow='autoplay; encrypted-media'
              style={{width: 0, height: 0}}
              title='video'
            />}
            {completed ? hofWinData.amount > 0 ?
              <img src={ScratchWinCoinImage} alt="coin img" className={classes.scratchWinCoinImage} /> :
              <img src={Confetti} alt="coin img" className={classes.scratchWinCoinImage} />
            : (<div className={classes.scratchContainer} >
                {hofWinData.amount > 0 && <div>
                  <img src={BenggaCoinImage} className={classes.scratchWinCoinText} style={{position: 'absolute'}}/>
                  <div className={classes.scratchContainer}>
                    <p className={classes.coin}>{hofWinData.amount}</p>
                  </div>
                </div>}
                {hofWinData.product && <div>
                  <img src={hofWinData.product.imageBig} className={classes.scratchWinProductImage} style={{position: 'absolute'}}/>
                </div>}
                <div className={classes.scratchContainer}>
                  <ScratchMe
                    width={280}
                    height={280}
                    foregroundImageSrc={ScrachCardFrontImage}
                    backgroundImageSrc={null}
                    onCompleted={() => { setCompleted(true) }}
                    completedAt={60}
                    strokeWidth={30}
                  />
                </div>
              </div>
            )}
          </div>
          {completed === false &&
            <p className={classes.scratchRevealText}>SCRATCH TO REVEAL BONUS</p>
          }
        </div>
      </div>
    </>
  );
};

export default ScratchCard;
