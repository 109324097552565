import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    headerContainer: {
        background: '#000',
        width: 'calc(100% + 0px)',
        // maxWidth: '350px',
        display: 'flex',
        alignItems: 'center',
        padding: '10px 0px',
        margin: '0',
        // position: 'absolute',
        // top: 0, left: 0
    },
    backBtn: {
        width: '20px',
        height: '20px',
        padding: 0,
        margin: 0,
        border: 0,
        background: 'transparent',
        cursor: 'pointer',
        display: 'block'
    },
    backBtnIcon: {
        width: '20px',
        height: '20px',
        color: '#c0c0c0'
    },
    headerTitle: {
        color: '#03abbd',
        fontSize: '16px',
        fontWeight: 500,
        margin: '0px auto',
        fontFamily: 'canaroBook'
    },
    soundBtn: {
        width: '20px',
        height: '20px',
        padding: 0,
        margin: 0,
        border: 0,
        background: 'transparent',
        cursor: 'pointer',
        display: 'block'
    },
    soundBtnIcon: {
        width: '20px',
        height: '20px',
        color: '#c0c0c0'
    },
    winnerBtn: {
        width: '26px',
        height: '26px',
        padding: 0,
        margin: 0,
        border: 0,
        background: 'transparent',
        cursor: 'pointer',
        display: 'block'
    },
    winnerBtnIcon: {
        width: 26,
        height: 26,
        color: '#c0c0c0',
        objectFit: 'contain'
    },
}));
