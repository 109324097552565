import React from 'react';
import useStyles from './styles';
import ScoreImage from '../../assets/images/header-images/Bengga Coin.png';
import {formatNumberWithComma} from '../../utils/utils';

const ScoreCounter = (props) => {
  const classes = useStyles();
  const balance = formatNumberWithComma(props.balance, props.isCrypto ? 4 : 0)
  return (
    <div className={classes.scoreCounterContainer}>
      <img className={classes.scoreImage} src={props.isCrypto ? props.cryptoImage : ScoreImage} alt='Score' />
      <div className={classes.scoreContainer}>
        <label className={classes.scoreLabel}>{balance}</label>
      </div>
    </div>
  );
};

export default ScoreCounter;
