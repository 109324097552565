import React, { useState } from 'react';
import useStyles from './style';
import Winners from '../../assets/images/header-images/winners.png';

const InnerPageHeader = (props) => {
    const [soundClick, setSoundClick] = useState(true);
    const classes = useStyles();
    return (
        <div className={classes.headerContainer}>
            {props.hasBackBtn &&
                <button className={classes.backBtn} onClick={() => {
                    if (props.goBackClick){
                        props.goBackClick();
                    }
                }}>
                    <svg className={classes.backBtnIcon} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                </button>
            }
            <p className={classes.headerTitle}>{props.title}</p>
            {props.hasSoundBtn &&
                <button
                    className={classes.soundBtn}
                    onClick={() => {
                        setSoundClick(!soundClick);
                        if (props.onSoundPress) {
                            props.onSoundPress(!soundClick);
                        }
                    }}>
                    <svg className={classes.soundBtnIcon} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        {soundClick ? (
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
                        ) : (
                            <>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" clipRule="evenodd" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2" />
                            </>
                        )}
                    </svg>
                </button>
            }
            {props.hasWinnersBtn &&
                <button
                    className={classes.winnerBtn}
                    onClick={() => {
                        if (props.onWinnerPress) {
                            props.onWinnerPress();
                        }
                    }}>
                    <img className={classes.winnerBtnIcon} src={Winners}>
                    </img>
                </button>
            }
        </div>
    );
};

export default InnerPageHeader;
