import { makeStyles } from "@material-ui/core/styles";
import useWindowDimensions from "../../utils/windowDimention";

import {
  isMobile,
  browserName,
  isAndroid,
  isIOS,
  isChrome,
  isSafari,
} from "react-device-detect";

// const { height } = useWindowDimensions();
export default makeStyles((theme) => ({
  homeContainer: {
    height: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  homeContent: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
  },
  sliderIndicatorContainer: {
    position: "absolute",
    top:  (isIOS && "40%") || 
    (!isIOS && "50%"),
    left: 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transform: "translateY(-50%)",
  },
  sliderIndicator: {
    border: "2px solid white",
    width: 7,
    height: 7,
    border: "1px solid white",
    borderRadius: "50%",
    marginBottom: 8,
    marginLeft: "auto",
    marginRight: "auto",
    cursor: "pointer",
  },
  gameSliderImageContent: {
    maxWidth: "480px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    padding: "0 10px",
    color: "#949494",
  },
  footericn: {
    cursor: "pointer",
    width: 50,
    height: 50,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 4,
    paddingBottom: 20,
    zIndex: 3,
    objectFit: "contain",
  },
  mainfooter: {
    position: "absolute",
    top:
      (isIOS && "calc(100vh - 230px)") ||
      (isAndroid && "calc(100vh - 200px)") ||
      (!isMobile && "calc(100vh - 149px)"),
    width: "100%",
    zIndex: 1,
  },
  tutorialImage: {
    width: 280,
    height: 280,
    objectFit: "cover",
  },
  tutorialContainer: {
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  mainMoreCoverContainer: {
    position: "absolute",
    bottom: 160,
    width: 80,
    right: 0,
    zIndex: 2,
    opacity: 1,
    transition: "opacity 3s ease",
  },
  moreCover: {
    display: "flex",
    alignItems: "space-between",
    color: "#fff",
    padding: "0 10px",
    flexDirection: "column",
  },
  tutorialicn: {
    cursor: "pointer",
    width: 60,
    height: 60,
    padding: 10,
    zIndex: 3,
    objectFit: "contain",
    position: "absolute",
    right: "40%",
    bottom: 0,
  },
  moreicn: {
    cursor: "pointer",
    width: 60,
    height: 60,
    padding: 10,
    zIndex: 3,
    objectFit: "contain",
    position: "absolute",
    left: "40%",
    bottom: 0,
  },
  morefooter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    padding: "0 10px",
    color: "#949494",
  },
  leftfootericn: {
    flex: 0.5,
    cursor: "pointer",
    height: 50,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 4,
    paddingBottom: 20,
    zIndex: 3,
    objectFit: "contain",
    marginRight: (isMobile && 280) ||
    (!isMobile && 380),
  },
  rightfootericn: {
    flex: 0.5,
    cursor: "pointer",
    height: 50,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 4,
    paddingBottom: 20,
    zIndex: 3,
    objectFit: "contain",
    marginRight: (isMobile && 280) ||
    (!isMobile && 380),
  },
  hostContainer: {
    top: 100,
    width: "100%",
    position: "absolute",
    height: 120,
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  hostedBy: {
    fontFamily: "CanaroBook",
    fontSize: 14,
    width: "100%",
    color: "#eeeeee",
    textAlign: "center",
  },
  hostImgContainer: {
    display: "flex",
    width: 120,
    height: 80,
    justifyContent: "center",
    // backgroundColor: 'red',
    // borderRadius: '50%',
    // border: "1px solid #00acb8"
    borderRadius: "50%",
    marginTop: 5,
  },
  hostImgContainer2: {
    display: "flex",
    width: 80,
    height: 80,
    // backgroundColor: 'red',
    // borderRadius: '50%',
    // border: "1px solid #00acb8"
    borderRadius: "50%",
  },
  hostImg: {
    justifyContent: "center",
    width: 80,
    height: 80,
    left: 20,
    borderRadius: "50%",
    objectFit: "cover",
  },
  host: {
    fontFamily: "CanaroMedium",
    width: "100%",
    fontSize: 16,
    color: "white",
    width: "100%",
    textAlign: "center",
  },
  modalCircle: {
    position: "absolute",
    backgroundColor: "white",
    width: "80px",
    height: "80px",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "50px",
    left: "40%",
    alignSelf: "center",
    marginTop: "-100%",
    border: "2px solid #28BBB1",
    opacity: 0.75,
  },
  lockImage: {
    width: 40,
    margin: "auto",
    display: "flex",
    padding: 10,
  },
}));
