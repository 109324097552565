import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  ticketImage: {
    width: 50,
  },
  ticketContainer: {
    position: 'relative',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  ticketCounter: {
    position: 'absolute',
    bottom: -4,
    right: -25,
    background: '#3F3E3C',
    color: 'white',
    borderRadius: 30,
    minWidth: 21,
    padding: '0px 10px',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 1.5,
    fontFamily: 'canaroBook',
  },
}));
