import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ScoreImage from "../../assets/images/header-images/Bengga Coin.png";
import Lock from "../../assets/images/common-images/lock.png";
import Unlock from "../../assets/images/common-images/unlock.png";
import TicketImage from "../../assets/images/header-images/ticket-header.png";
import { buyBrandStoreItem, getSalesPromo, payOneTimeFee, getOneTimePay } from "../../services/ApiService";
import { ids } from "../../constants";
import { nftHelper } from "../../helpers/nftHelper";
import { isIOS, isMobile } from "react-device-detect";

const HLCover = (props) => {
  const navigate = useNavigate();

  const styles = {
    mainContainer: {
      // backgroundColor: "red",
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1,
    },

    arrowContainer: {
      position: "absolute",
      left: 10,
      marginBottom: 680,
      cursor: "pointer",
    },
    backArrow: {
      width: 30,
    },
    modalCircle: {
      position: "absolute",
      backgroundColor: "white",
      width: "90px",
      height: "90px",
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "50px",
      left: (isMobile && "38%") || (!isMobile && "41%"),
      top: (isIOS && 'calc(10vh + 50px)') || 
      (isMobile && 'calc(10vh + 15px)') || 
      (!isMobile && 'calc(27vh + 15px)'),
      border: "2px solid #28BBB1",
      zIndex: 2
    },
    lockImage: {
      width: 60,
      margin: "auto",
      display: "flex",
      paddingTop: 12,
    },
    modalBox: {
      backgroundColor: "white",
      height: "65%",
      maxHeight: 375,
      minWidth: 250,
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "10px",
      border: "2px solid #28BBB1",
      marginBottom: 20
    },
    headerContainer: {
      width: "100%",
      height: 100,
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      marginTop: "-20px",
    },
    headerText: {
      color: "grey",
      fontWeight: "700",
      fontSize: "15px",
      justifyContent: "center",
      paddingTop: 80,
      textAlign: "center",
      width: "100%",
    },
    text: {
      color: "grey",
      fontSize: "12px",
      justifyContent: "center",
      paddingTop: 5,
      textAlign: "center",
      width: "100%",
    },
    squareImageContainer: {
      width: "80%",
      height: '95px',
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      margin: "auto",
      paddingTop: '10px',
      paddingBottom: 20,
    },
    squareImage: {
      width: 200,
      height: 200,
      borderRadius: "10px",
      margin: "auto",
      justifyContent: "center",
      display: "flex",
      objectFit: 'contain'
    },

    scoreCounterContainer: {
      width: "90%",
      left: '5%',
      height: 50,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      marginTop: 150,
      marginLeft: 0,
      // margin: "auto",
      backgroundColor: "#5fc9bf",
      borderRadius: 6,
      position: "relative",
    },

    priceText: {
      width: "55%",
      height: 50,
      // alignItems: "center",
      // justifyContent: "center",
      color: "white",
      fontWeight: "700",
      fontSize: "9px",
      // textAlign: "center",
      paddingTop: 20,
      paddingLeft: 10,
    },

    scoreContainer: {
      width: "40%",
      height: 50,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      margin: "auto",
      marginLeft: 0
    },
    scoreImage: {
      width: 30,
      alignItems: "center",
      justifyContent: "center",
      // paddingLeft: 10,
    },
    scoreLabel: {
      color: "white",
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: 1,
      paddingLeft: 5,
    },

    descriptionContainer: {
      width: 280,
      height: 50,
      justifyContent: "space-evenly",
      flexDirection: "row",
      alignItems: "center",
      display: "flex",
      position: "relative",
      paddingTop: 40,

      // margin: "auto",
    },
    description: {
      color: "grey",
      fontWeight: 500,
      fontSize: 13,
      lineHeight: 1,
      textAlign: "center",
      // paddingLeft: 30,
    },
    ticketImage: {
      width: 50,
    },
    ticketContainer: {
      position: "relative",
      marginTop: "auto",
      marginBottom: "auto",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      paddingTop: 15,
    },

    // buttonCancelContainer: {
    //   color: "grey",
    //   fontWeight: 500,
    //   fontSize: "15px",
    //   lineHeight: 1,
    //   border: "1px solid #28BBB1",
    //   width: 90,
    //   height: 25,
    //   textAlign: "center",
    //   borderRadius: 30,
    //   paddingTop: 0,
    //   justifyContent: "center",
    //   cursor: "pointer",
    // },
    buttonCancelContainer: {
      backgroundColor: "transparent",
      fontWeight: 500,
      fontSize: "15px",
      width: 50,
      height: 50,
      position: 'absolute',
      right: (isMobile && "17%") ||
      (!isMobile && "23%"),
      borderColor: 'transparent',
      textAlign: "center",
      paddingTop: 0,
      justifyContent: "center",
      cursor: "pointer",
      color: '#707070',
      fontFamily: 'CanaroMedium'
    },
    buttonBuyContainer: {
      color: "white",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: 1,
      border: "1px solid #28BBB1",
      backgroundColor: "#28BBB1",
      width: 100,
      height: 30,
      textAlign: "center",
      borderRadius: 30,
      paddingTop: 0,
      position: 'relative',
      top: 115,
      justifyContent: "center",
      cursor: "pointer",
      fontFamily: 'CanaroMedium'
    },
    buttonCloseContainer: {
      color: "#a0a0a0",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: 1,
      border: "1px solid #a0a0a0",
      backgroundColor: "white",
      width: 100,
      height: 30,
      textAlign: "center",
      borderRadius: 30,
      paddingTop: 0,
      position: 'relative',
      top: 115,
      justifyContent: "center",
      cursor: "pointer",
      fontFamily: 'CanaroMedium'
    },

    title: {
      fontSize: 18,
      fontFamily: 'CanaroMedium',
      color: '#404040',
      textAlign: 'center',
      marginTop: 60,
      height: 12
    },
    text1: {
      fontSize: 12,
      fontFamily: 'CanaroBook',
      color: '#404040',
      textAlign: 'center',
      height: 10
    },
    text2: {
      fontSize: 12,
      fontFamily: 'CanaroBook',
      color: '#00acb8',
      textAlign: 'center',
      height: 10
    },
    text3: {
      fontSize: 12,
      fontFamily: 'CanaroBook',
      color: '#404040',
      textAlign: 'center',
      marginTop: 20
    },
  };

  const [isPaid, setIsPaid] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);

  const fetchOneTimePay = async() => {
    try {
      const response = await getOneTimePay();
      if (response) {
        const paidData = response.data.data.filter((item) => item.engagementId === props.engagementData.newId)
        if (paidData.length > 0) {
          setIsPaid(true)
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  const fetchData = async () => {
    try {
      const response = await getSalesPromo({storeItemId: (JSON.parse(props.engagementData.joinRequirement) || []).filter(j => j.type == 2)[0].storeItemId});
      // console.log(`response=${JSON.stringify(response)}`)
      if (typeof response.data.data !== 'undefined' && response.data.data.length > 0) {
        if(response.data.data[0].bought && response.data.data[0].bought > 0) {
          setIsPaid(true);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(async () => {
    // fetchData();
    fetchOneTimePay();
    const oldId = localStorage.getItem("oldId");
    switch (oldId) {
      case "30485860-caf6-11e3-91ec-123139260fba":
      case "92d3ae60-725e-11ea-89b2-0242ac110008":
      case "ec3d5250-87ba-11ea-9ac1-0242ac110008":
      case "c0c0dfc0-eead-11ea-a413-0242ac11000c":
      case "5ec02ed0-7553-11ea-abd2-0242ac110008":
      case "2d1d3420-d9c7-11eb-904b-86daee0498db":
      case "0060a390-b659-11eb-8e20-924d266ce740":
      case "00614020-f470-11eb-bf89-aa9e76aedffd":
      case "00614400-d880-11eb-a293-3ad15f7fd6a1":
      case "00615960-dcc5-11eb-9892-168b6e73f4be":
      case "0061cbb0-f8aa-11eb-8827-d292e928ff1d":
      case "0061d8d0-0057-11ec-997b-12ddffe82d3a":
      case "00629880-ba00-11eb-b709-4a5ab3aa9b52":
      case "0062e2d0-fead-11eb-9ead-faac3a0948c6":
      case "0063cde0-ca4f-11eb-a3a5-427dfbda9bef":
      case "00649e60-c774-11eb-ba14-aeab16f6b412":
      case "0064eec0-f2b1-11eb-8a95-36e6d441953c":
      case "0064fb70-ec32-11eb-897a-4618411deb55":
      case "006531e0-f32a-11eb-8a95-36e6d441953c":
      case "0066f580-e938-11eb-897a-4618411deb55":
        setIsAllowed(true);
        break;
    }
    const creatorId = JSON.parse(props.engagementData.joinRequirement).filter(j => j.type == 3)[0].creatorId;
    const data = {
      user_wallet: localStorage.getItem("maiarAddress"),
      creator_wallet: creatorId
    };
    const nft = await nftHelper(data);
    if (nft.length > 0) {
      setIsAllowed(true);
    }
  }, [])

  // const onBuy = async () => {
  //   if (!isPaid) {
  //     // console.log(`localStorage.getItem("wefftWalletAddress")=${localStorage.getItem("wefftWalletAddress")}`)
  //     console.log(`localStorage.getItem("balance")=${localStorage.getItem("balance")}; props.engagementData.joinFee=${props.engagementData.joinFee}`)
  //     const wefftWalletAddress = localStorage.getItem("wefftWalletAddress");
  //     const balance = localStorage.getItem("balance") && JSON.parse(localStorage.getItem("balance"));
  //     if (balance.coinAmount < props.engagementData.joinFee) {
  //       window.alert(`Not enought coins`);
  //     }
  //     else {
  //       try {
  //         const params = {
  //           storeId: ids.benggaStoreId,
  //           storeItemId: (JSON.parse(props.engagementData.joinRequirement) || []).filter(j => j.type == 2)[0].storeItemId,
  //           engagementId: props.engagementData.id,
  //           parts: 1,
  //           bought: 1,
  //           wefftWalletAddress
  //         }
  //         await buyBrandStoreItem(params)
  //       }
  //       catch(e) {
  //         console.log(e)
  //       }
  //       props.onJoin();
  //     }
  //   }
  //   else {
  //     props.onJoin();
  //   }
  // }

  const onBuy = async () => {
    if (!isAllowed) {
      props.onCancel()
    }
    else {
      try {
        if (!isPaid) {
          const feeParam = {
            "engagementId": props.engagementData.newId,
            "amount": 0,
            "pot": 1,
            "isOneTime": 1
          }
          await payOneTimeFee(feeParam)
        }
        props.onJoin();
      }
      catch(e) {
        console.log(e)
      }
    }
  }

  return (
    <div style={styles.mainContainer}>
      {/* <div style={styles.arrowContainer} onClick={() => history("/")}>
        <img style={styles.backArrow} src={Arrow} alt="Arrow" />
      </div> */}
      <div style={styles.modalCircle}>
        <img style={styles.lockImage} src={isAllowed ? Unlock : Lock} alt="lock" />
      </div>
      <div style={styles.modalBox}>
        <div >
          <button onClick={() => props.onCancel()} style={styles.buttonCancelContainer}>
            X
          </button>  
        </div>
        {/* <div style={styles.headerContainer}>
          <div style={styles.headerText}>{`Rank 1 - ${props.engagementData.topPlayers} to win`}</div>
        </div>
        <div style={styles.text}>{`Number of winners: ${props.engagementData.topPlayers}`}</div> */}
        {isAllowed && <div>
          <p style={styles.title} >Welcome!</p>
          {/* <p style={styles.text3}>This game is exclusive for you</p>
          <p style={styles.text1}>and other <label style={styles.text2}>NFT holders </label>of</p> */}
          <p style={styles.text1}>for NFT holders of <label style={styles.text2}>{props.engagementData.source.displayName}</label></p>
          <div style={styles.squareImageContainer}>
            <img style={styles.squareImage} src={JSON.parse(props.engagementData.joinRequirement).filter(j => j.type == 3)[0].imageBig} alt="Egld" />
          </div>
        </div>}
        {!isAllowed && <div>
          <p style={styles.title} >Exclusive Game</p>
          <p style={styles.text1}>for NFT holders of <label style={styles.text2}>{props.engagementData.source.displayName}</label></p>
          <div style={styles.squareImageContainer}>
            <img style={styles.squareImage} src={JSON.parse(props.engagementData.joinRequirement).filter(j => j.type == 3)[0].imageBig} alt="Egld" />
          </div>
        </div>}
        {/* <div style={styles.squareImageContainer}>
          <img style={styles.squareImage} src={JSON.parse(props.engagementData.joinRequirement).filter(j => j.type == 2)[0].imageBig} alt="Egld" />
        </div>
        <div style={styles.scoreCounterContainer}>
          <label style={styles.priceText}>{"One-time entry free = "}</label>

          {isPaid && <div style={styles.scoreContainer}>
            <label style={styles.scoreLabel}>PAID</label>
          </div>}
          {!isPaid && <div style={styles.scoreContainer}>
            <img style={styles.scoreImage} src={ScoreImage} alt="Score" />
            <label style={styles.scoreLabel}>{props.engagementData.joinFee}</label>
          </div>} 
        </div>*/}

        {/* <div style={styles.descriptionContainer}>
          <div />
          <label style={styles.description}>{" Use"}</label>
          <img style={styles.ticketImage} src={TicketImage} alt="Score" />
          <div />
        </div> */}
        <div style={styles.buttonContainer}>
          {/* <button style={styles.buttonCancelContainer} onClick={() => props.onCancel()}>
            Cancel
          </button> */}
          <button style={isAllowed ? styles.buttonBuyContainer : styles.buttonCloseContainer} onClick={() => onBuy()}>
            { isAllowed ? 'JOIN' : 'CLOSE' }
            {/* {'JOIN'} */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HLCover;
