import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dataContainer: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    bottom: 140,
  },
  dataLabel: {
    color: 'white',
    fontSize: 18,
    fontFamily: 'canaroBook',
  },
}));
