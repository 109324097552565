import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  profileImage: {
    width: 38,
    height: 38,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  profileContainer: {
    position: 'relative',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: 8
  },
  profileCounter: {
    position: 'absolute',
    top: 0,
    right: -8,
    background: '#3F3E3C',
    color: 'white',
    borderRadius: 30,
    minWidth: 21,
    height: 21,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 1.5,
    fontFamily: 'canaroBook',
  },
}));
