import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import InnerPageHeader from "../../components/InnerPageHeader";
import ScratchMe from "react-scratch-me";
import useStyles from "./styles";
import BlueCoinImage from "../../assets/images/BlueCoinImage.png";
import BenggaCoinImage from "../../assets/images/header-images/Bengga Coin.png";

import FavoritesLogo from "../../assets/images/logo/famers.png";
import Level5Image from "../../assets/images/postloader/famer_win_5.png";

import { labels } from "../../utils/LangSettings";
import ScoreCounter from "../../components/ScoreCounter";
import { routes } from "../../constants";

const ScratchWin = () => {
  const classes = useStyles();
  const props = useLocation().state;
  const navigate = useNavigate();
  const [balance, setBalance] = useState(0);

  useEffect(async () => {
    const balance =
      localStorage.getItem("balance") &&
      JSON.parse(localStorage.getItem("balance"));
    setBalance(balance);
    window.onpopstate = () => {
      navigate(routes.home)
    }
  }, []);

  return (
    <>
      <div className={classes.container}>
        <div style={{ paddingRight: 20 }}>
          <InnerPageHeader
            hasBackBtn
            goBackClick={() => {
              navigate(routes.home);
            }}
            title={`B: ${props.engagementData.title}`}
          />
        </div>
        <div className={classes.scratchWinContent}>
          <img
            src={FavoritesLogo}
            alt="favorites logo"
            className={classes.scratchWinSmallImageBox}
          />
          {props.hofWinData.amount > 0 && <span className={classes.scratchWinMuchText}>{labels.YWon}</span>}
          {props.hofWinData.product && <span className={classes.scratchWinMuchText}>Screenshot and tag @EffortEconomy on Twitter</span>}
          {props.hofWinData.amount > 0 && (
            <div>
              <div className={classes.coinImageText}>
                <div className={classes.coinImageTextRow}>
                  <img
                    src={BenggaCoinImage}
                    alt="bengga coin img"
                    className={classes.coinYellowImage}
                  />
                  <h2 className={classes.coinText}>
                    {props.hofWinData.amount}
                  </h2>
                </div>
                {/* <img className={classes.coinBlueImage} src={BlueCoinImage} alt="coin img" /> */}
              </div>
              <div className={classes.availableProtectCoin}>
                {/* <p className={classes.availableProtectCoinText}><i>50% {labels.AvailableCoins}</i></p>
              <p className={classes.availableProtectCoinText}><i>50% {labels.CoinsProtectTomorrow}</i></p> */}
              </div>
              <div className={classes.scoreCounterRow}>
                {/* <p className={classes.scoreCounterLeftText}>{labels.YNowWon}</p>
                <ScoreCounter
                  containerStyle={classes.scoreCounterContainer}
                  balance={props.balance.coinAmount + props.hofWinData.amount}
                /> */}
              </div>
            </div>
          )}
          {props.hofWinData.product && (
            <div>
              <img
                src={props.hofWinData.product.imageBig}
                alt="bengga coin img"
                className={classes.scratchWinProductImage}
              />
            </div>
          )}
          <img
            src={Level5Image}
            alt="level 5 img"
            className={classes.level5Image}
          />
        </div>
      </div>
    </>
  );
};

export default ScratchWin;
