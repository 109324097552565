import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  headerContainer: {
    position: "fixed",
    maxWidth: "480px",
    width: "100%",
    top: "15px",
    display: "flex",
    justifyContent: "space-between",
    // zIndex: 4,
    zIndex: 1,
    paddingRight: "15px",
    paddingLeft: "15px",
    transform: "translateX(-50%)",
    left: "50%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  ticketProfileContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "auto",
    marginBottom: "auto",
    width: 140,
    justifyContent: "flex-end",
  },
}));
