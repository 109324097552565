import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  homeContainer: {
    height: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  homeContent: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
    backgroundColor: "green",
  },
  homeContentLandscape: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
    transform: "rotate(270deg)",
  },
  sliderIndicatorContainer: {
    position: "absolute",
    top: "50%",
    left: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  sliderIndicator: {
    border: "2px solid white",
    width: 7,
    height: 7,
    border: "1px solid white",
    borderRadius: "50%",
    marginBottom: 8,
    marginLeft: "auto",
    marginRight: "auto",
    cursor: "pointer",
  },
  textFormat: {
    color: "#ffffff",
    fontSize: "16px",
    margin: 0,
    maxWidth: "392px",
    minWidth: "392px",
    backgroundColor: "transparent",
    paddingRight: 20,
  },
  notifContainer: {
    marginTop: 15,
    height: "120px",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  profileImage: {
    width: "48px",
    height: "48px",
    borderRadius: "24px",
    cursor: "pointer",
    marginLeft: 20,
    marginRight: 20,
  },

  track: {
    maxWidth: 320,
    minWidth: 320,
    height: 16,
    borderRadius: 8,
    border: "1px solid #00acb8",
  },
  name: {
    color: "#db3eb1",
    fontSize: "16px",
    maxWidth: "150px",
    minWidth: "150px",
    backgroundColor: "transparent",
    position: "absolute",
    left: 15,
    marginTop: -100,
  },
  marker: {
    position: "absolute",
    left: 10,
    width: 60,
    height: 60,
    backgroundColor: "transparent",
    borderRadius: "50%",
    border: "1px solid white",
  },
  score: {
    color: "#dddddd",
    fontSize: "16px",
    maxWidth: "150px",
    minWidth: "150px",
    backgroundColor: "transparent",
    position: "absolute",
    left: 290,
    marginTop: -100,
  },
}));
