import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  sidebarContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    minWidth: 210,
    background: '#262626',
    zIndex: 3,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
  },
  mask: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    background: 'transparent',
    zIndex: 2,
    right: 0,
  },
  profileContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '80%',
    marginTop: 15,
    height: 38
  },
  closeImage: { height: 36, cursor: 'pointer', marginTop: 2 },
  menuContainer: {
    marginTop: 20,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  singleMenu: {
    width: '75%',
    marginBottom: 25,
    display: 'flex',
    alignItems: 'center',
  },
  menuImage: {
    width: 25,
    display: 'flex',
  },
  menuLabel: {
    lineHeight: 1,
    color: 'white',
    marginLeft: 15,
    fontSize: 14,
    fontWeight: 500,
    cursor: 'pointer',
    fontFamily: 'canaroBook',
  },
  divider: {
    width: '100%',
    height: 2,
    background: '#393939',
    marginBottom: 20,
  },
  menuOuter: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  profileCounter: {
    position: 'absolute',
    top: 70,
    left: 40,
    background: '#3F3E3C',
    color: 'white',
    borderRadius: 30,
    minWidth: 21,
    height: 21,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 1.5,
    fontFamily: 'canaroBook',
  },
}));
