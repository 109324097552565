import Notification from '../assets/images/sidebar-images/notification.png';
import Boosts from '../assets/images/sidebar-images/boosts.png';
import Broadcasts from '../assets/images/sidebar-images/broadcasts.png';
import Collection from '../assets/images/sidebar-images/collection.png';
import History from '../assets/images/sidebar-images/history.png';
import Logout from '../assets/images/sidebar-images/logout.png';
import SoundOn from '../assets/images/sidebar-images/sound_on.png';

export const sidebarMenu = [
  {
    id: 0,
    label: 'Notification',
    icon: Notification,
  },
  // {
  //   id: 1,
  //   label: 'Boosts', 
  //   icon: Boosts,
  // },
  // {
  //   id: 2,
  //   label: 'Broadcasts', 
  //   icon: Broadcasts,
  // },
  {
    id: 3,
    label: 'Collection',
    icon: Collection,
  },
  {
    id: 4,
    label: 'History', 
    icon: History,
  },
  {
    id: 5,
    label: 'Sound', 
    icon: SoundOn,
  },
  {
    id: 6,
    label: 'Logout', 
    icon: Logout,
  },
];
