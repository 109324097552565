import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const UploadMedia = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  console.log("state", state);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        flexDirection: "column",
      }}
    >
      <button
        style={{ padding: "0.5rem 0.75rem", color: "red" }}
        onClick={() => navigate(-1)}
      >
        back
      </button>
      <br />
      <br />
      {JSON.stringify(state)}
    </div>
  );
};

export default UploadMedia;
