import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    maxWidth: '480px',
    width: '100%',
    padding: '0 20px',
    minHeight: '100vh',
    height: 'auto',
    position: 'relative',
    background: '#000',
    margin: '0 auto'
  },
  homeContent: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
  },
}));