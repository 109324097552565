import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  homeContainer: {
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  homeContent: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
  },
  homeContentLandscape: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
    transform: "rotate(270deg)",
  },
  sliderIndicatorContainer: {
    position: "absolute",
    top: "50%",
    left: 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  sliderIndicator: {
    border: "2px solid white",
    width: 7,
    height: 7,
    border: "1px solid white",
    borderRadius: "50%",
    marginBottom: 8,
    marginLeft: "auto",
    marginRight: "auto",
    cursor: "pointer",
  },
  description: {
    color: "#ffffff",
    fontSize: 12,
    margin: 0,
    marginTop: 12,
    maxWidth: 150,
    minWidth: 170,
    backgroundColor: "transparent",
    paddingRight: 10,
  },
  lastmoddate: {
    color: "#bbbbbb",
    fontSize: 10,
    marginTop: 5,
    maxWidth: 150,
    minWidth: 170,
    backgroundColor: "transparent",
    paddingRight: 10,
  },
  historyContainer: {
    height: 60,
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  profileImage: {
    width: 32,
    height: 32,
    borderRadius: 16,
    cursor: "pointer",
    marginLeft: 15,
    marginRight: 15,
  },
  titleContainer: {
    minWidth: 480,
    maxWidth: 480,
    height: 20,
    backgroundColor: "#00acb8",
    marginTop: 45,
  },
  title: {
    color: "white",
    fontFamily: "canaroMedium",
    fontSize: 16,
    position: "absolute",
    right: 10,
  },
  name: {
    color: "white",
    fontFamily: "canaroMedium",
    fontSize: 14,
    position: "absolute",
    left: 10,
    textAlign: 'left'
  },
  plus: {
    color: "green",
    fontFamily: "canaroMedium",
    fontSize: 13,
    maxWidth: 45,
    minWidth: 45,
    textAlign: "center",
    position: "absolute",
    right: 90,
  },
  minus: {
    color: "red",
    fontFamily: "canaroMedium",
    fontSize: 13,
    maxWidth: 45,
    minWidth: 45,
    textAlign: "center",
    position: "absolute",
    right: 90,
  },
  amount: {
    color: "white",
    fontFamily: "canaroMedium",
    fontSize: 14,
    maxWidth: 80,
    minWidth: 80,
    textAlign: "right",
    position: "absolute",
    right: 10,
  },
  engagementTitleContainer: {
    minWidth: 480,
    maxWidth: 480,
    height: 50,
    backgroundColor: "#00acb8",
  },
  engagementTitle: {
    color: "white",
    fontFamily: "canaroMedium",
    fontSize: 16,
    marginTop: 15,
  },
  winnersContainer: {
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 15,
  },
  winnerContainer: {
    minWidth: 480,
    maxWidth: 480,
    height: 50,
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  score: {
    color: "#dddd",
    fontFamily: "canaroMedium",
    fontSize: 13,
    maxWidth: 45,
    minWidth: 45,
    textAlign: "center",
    position: "absolute",
    right: 50,
  },
  winnings: {
    color: "white",
    fontFamily: "canaroMedium",
    fontSize: 14,
    maxWidth: 80,
    minWidth: 80,
    textAlign: "right",
    position: "absolute",
    right: 60,
  },
  rank: {
    color: "#db3eb1",
    fontFamily: "canaroMedium",
    fontSize: 13,
    maxWidth: 45,
    minWidth: 45,
    textAlign: "center",
    position: "absolute",
    left: 60,
  },
  winnerImage: {
    width: 32,
    height: 32,
    borderRadius: 16,
    cursor: "pointer",
    marginLeft: 15,
    marginRight: 15,
    position: "absolute",
    left: 100,
  },
  winnername: {
    color: "white",
    fontFamily: "canaroMedium",
    fontSize: 14,
    position: "absolute",
    left: 170,
    textAlign: 'left'
  },
}));
