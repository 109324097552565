import React from "react";
import useStyles from "./styles";

const MoreFooter = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.mainfooter}>
      <div className={classes.morefooter}>
        <div onClick={props.onLeftIconPress}>
          <img src={props.leftIcon} className={classes.leftfootericn} />
        </div>
        <div onClick={props.onRightIconPress}>
          <img src={props.rightIcon} className={classes.rightfootericn} />
        </div>
      </div>
    </div>
  );
};

export default MoreFooter;
