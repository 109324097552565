import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import useStyles from './styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import useWindowDimensions from '../../utils/windowDimention';
import Loader from "../../components/Common/Loader";
import InnerPageHeader from '../../components/InnerPageHeader';
import moment from "moment";
import Placeholder from '../../assets/images/common-images/user_placeholder.png'
import { formatNumberWithComma } from '../../utils/utils';

import {
  getTourneyWinners
} from "../../services/ApiService";

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Winners = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [referenceDate, setReferenceDate] = useState();

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getTourneyWinners();
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response) {
        if (typeof response.data.data !== 'undefined' && response.data.data.length > 0) {
          let dataSource = [];
          response.data.data.forEach(item => {
            let filtered = dataSource.filter(d => d.engagementId == item.engagementId)
            if (filtered.length == 0) {
              dataSource.push({engagementId: item.engagementId, engagementTitle: `B: ${item.engagementTitle}`})
            }
          })
          // console.log(`dataSource=${JSON.stringify(dataSource)}`)
          setHeaderData(dataSource);
          setData(response.data.data);
          setReferenceDate(response.data.data[0].referenceDate);
        }
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  return (
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      <div className={classes.homeContainer}>
        <div style={{ width: '100%', alignItems: 'center', maxWidth: 480, marginTop: '40px' }}>
          {headerData.length > 0 && headerData.map((item) => {
            return (<div className={classes.winnersContainer} key={item.engagementId} style={{backgroundColor: '#00acb8'}}>
              <div className={classes.engagementTitleContainer} >
                <p className={classes.engagementTitle} style={{textAlign: 'center'}}>{item.engagementTitle}</p>
              </div>
              {/*} return (<div className={classes.historyContainer} key={item.engagementId} style={{backgroundColor: index % 2 === 0 ? '#303030' : 'transparent'}}>
                <img src={image} className={classes.profileImage} />
                `${item.engagementId}_${item.playerId}`
                <div>
                  <p className={classes.description}>{item.description}</p>
                  <p className={classes.lastmoddate}>{moment(item.lastModifiedDate).format("YYYY-MM-DD HH:mm:ss")}</p>
                </div>
                <p className={item.amount > 0 ? classes.plus : classes.minus }>{item.amount > 0 ? '+' : '-'}{formatNumberWithComma(item.amount, 0)}</p>
                <p className={classes.amount }>{formatNumberWithComma(item.serverBalance,0)}</p> */}
                {data.filter(d => d.engagementId == item.engagementId).length > 0 && data.filter(d => d.engagementId == item.engagementId).map((subData, index) => {
                    return <div className={classes.winnerContainer} key={`${subData.engagementId}_${subData.playerId}`} style={{backgroundColor: index % 2 === 0 ? '#303030' : 'black'}}>
                      <p className={classes.rank}>{subData.rank}</p>
                      <img src={subData.playerAvatar ? subData.playerAvatar : Placeholder} className={classes.winnerImage} />
                      <p className={classes.winnername}>{subData.playerName}</p>
                      <p className={classes.score}>{formatNumberWithComma(subData.score,0)}</p>
                      {/* <p className={classes.winnings}>{formatNumberWithComma(subData.amount,0)}</p> */}
                    </div>
                  })
                }
              </div>)}
            )
          }
        </div>
        <div style={{position: 'absolute', top: 0, paddingLeft: 20, paddingRight: 40, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
          <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title={`Winners (${moment(referenceDate).format('YYYY-MM-DD')})`} style={{position: 'absolute'}}/>
        </div>
      </div>
    </div>
  );
};

export default Winners;
