import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ScoreImage from "../../assets/images/header-images/Bengga Coin.png";
import Lock from "../../assets/images/common-images/lock.png";
import Unlock from "../../assets/images/common-images/unlock.png";
import TicketImage from "../../assets/images/header-images/ticket-header.png";
import { checkAndPayOneTimeFee, getOneTimePay } from "../../services/ApiService";
import { ids } from "../../constants";
import { formatNumberWithComma } from "../../utils/utils";
import { isIOS, isMobile } from "react-device-detect";
import TourneyLogo from '../../assets/images/footer-images/tourney.png';

const TourneyCover = (props) => {
  const navigate = useNavigate();

  const styles = {
    mainContainer: {
      // backgroundColor: "red",
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1,
    },

    arrowContainer: {
      position: "absolute",
      left: 10,
      marginBottom: 680,
      cursor: "pointer",
    },
    backArrow: {
      width: 30,
    },
    modalCircle: {
      position: "absolute",
      backgroundColor: "white",
      width: "90px",
      height: "90px",
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "50px",
      left: (isMobile && "38%") || (!isMobile && "41%"),
      top: (isIOS && 'calc(10vh + 45px)') || 
      (isMobile && 'calc(10vh + 15px)') || 
      (!isMobile && 'calc(27vh + 15px)'),
      border: "2px solid #28BBB1",
      zIndex: 2
    },
    lockImage: {
      width: 60,
      margin: "auto",
      display: "flex",
      paddingTop: 12,
    },
    modalBox: {
      backgroundColor: "white",
      height: "65%",
      maxHeight: 375,
      minWidth: 250,
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "10px",
      border: "2px solid #28BBB1",
      marginBottom: 20
    },
    headerContainer: {
      width: "100%",
      height: 100,
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      marginTop: "-20px",
    },
    headerText: {
      color: "grey",
      fontWeight: "700",
      fontSize: "15px",
      justifyContent: "center",
      paddingTop: 80,
      textAlign: "center",
      width: "100%",
    },
    text: {
      color: "grey",
      fontSize: "12px",
      justifyContent: "center",
      paddingTop: 5,
      textAlign: "center",
      width: "100%",
    },
    squareImageContainer: {
      width: "80%",
      height: 100,
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      margin: "auto",
      paddingTop: 10,
      paddingBottom: 10,
    },
    squareImage: {
      width: 200,
      height: 150,
      borderRadius: "10px",
      margin: "auto",
      justifyContent: "center",
      display: "flex",
      objectFit: 'contain'
    },

    scoreCounterContainer: {
      width: "90%",
      left: '5%',
      height: 50,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      marginTop: 70,
      marginLeft: 0,
      // margin: "auto",
      backgroundColor: "#5fc9bf",
      borderRadius: 6,
      position: "relative",
    },

    priceText: {
      width: "55%",
      height: 50,
      // alignItems: "center",
      // justifyContent: "center",
      color: "white",
      fontWeight: "700",
      fontSize: "9px",
      // textAlign: "center",
      paddingTop: 20,
      paddingLeft: 10,
    },

    scoreContainer: {
      width: "40%",
      height: 50,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      margin: "auto",
      marginLeft: 0
    },
    scoreImage: {
      width: 30,
      alignItems: "center",
      justifyContent: "center",
      // paddingLeft: 10,
    },
    scoreLabel: {
      color: "white",
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: 1,
      paddingLeft: 15,
      paddingTop: 5
    },
    amountLabel: {
      color: "white",
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: 1,
      paddingLeft: 5,
      paddingTop: 5
    },
    descriptionContainer: {
      width: 280,
      height: 50,
      justifyContent: "space-evenly",
      flexDirection: "row",
      alignItems: "center",
      display: "flex",
      position: "relative",
      paddingTop: 40,

      // margin: "auto",
    },
    description: {
      color: "grey",
      fontWeight: 500,
      fontSize: 13,
      lineHeight: 1,
      textAlign: "center",
      // paddingLeft: 30,
    },
    ticketImage: {
      width: 50,
    },
    ticketContainer: {
      position: "relative",
      marginTop: "auto",
      marginBottom: "auto",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      paddingTop: 10,
    },

    // buttonCancelContainer: {
    //   color: "grey",
    //   fontWeight: 500,
    //   fontSize: "15px",
    //   lineHeight: 1,
    //   border: "1px solid #28BBB1",
    //   width: 90,
    //   height: 25,
    //   textAlign: "center",
    //   borderRadius: 30,
    //   paddingTop: 0,
    //   justifyContent: "center",
    //   cursor: "pointer",
    // },
    buttonCancelContainer: {
      backgroundColor: "transparent",
      fontWeight: 500,
      fontSize: "15px",
      width: 50,
      height: 50,
      position: 'absolute',
      right: (isMobile && "17%") ||
      (!isMobile && "23%"),
      borderColor: 'transparent',
      textAlign: "center",
      paddingTop: 0,
      justifyContent: "center",
      cursor: "pointer",
      color: '#707070',
      fontFamily: 'CanaroMedium'
    },
    buttonBuyContainer: {
      color: "white",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: 1,
      border: "1px solid #28BBB1",
      backgroundColor: "#28BBB1",
      width: 100,
      height: 30,
      textAlign: "center",
      borderRadius: 30,
      paddingTop: 0,
      justifyContent: "center",
      cursor: "pointer",
      fontFamily: 'CanaroMedium'
    },
  };

  const [isFree, setIsFree] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [fetchedOneTime, setFetchedOneTime] = useState(false);
  const [oneTimePayData, setOneTimePayData] = useState(props.oneTimePayData);
  const [isClicked, setIsClicked] = useState(false);

  const fetchOneTimePay = async() => {
    try {
      const response = await getOneTimePay();
      if (response) {
        setOneTimePayData(response.data.data);
        // console.log(`oneTimePayData=${JSON.stringify(response.data.data)}; props.engagementData.newId=${props.engagementData.newId}`)
        const paidData = response.data.data.filter((item) => item.engagementId === props.engagementData.newId)
        if (paidData.length > 0) {
          setIsPaid(true)
          setFetchedOneTime(true);
          setTimeout(() => {
            props.onJoin();
          }, 1000);
        }
        else {
          fetchOneTimePay(true);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(async () => {
    // console.log(`props.oneTimePayData=${JSON.stringify(props.oneTimePayData)}; props.engagementData.newId=${props.engagementData.newId}`)
    if (props.engagementData.joinFeeType == 1) { //free
      setIsFree(true);
    }
    else {
      if (typeof oneTimePayData !== 'undefined' && oneTimePayData) {
        const paidData = oneTimePayData.filter((item) => item.engagementId === props.engagementData.newId)
        if (paidData.length > 0) {
          setIsPaid(true)
          setFetchedOneTime(true)
          setTimeout(() => {
            props.onJoin();
          }, 1000);
        }
      }
      else {
        await fetchOneTimePay();
      }
    }
  }, [])

  const onJoin = async () => {
    if (isFree) {
      props.onJoin();
    }
    else {      
      if (!isPaid) {
        const balance = localStorage.getItem("balance") && JSON.parse(localStorage.getItem("balance"));
        if (balance.coinAmount < props.engagementData.joinFee) {
          window.alert(`Not enough coins`);
        }
        else {
          if (!isClicked) {
            setIsClicked(true);
            try {
              const feeParam = {
                "engagementId": props.engagementData.newId,
                "engagementTitle": props.engagementData.title,
                "amount": props.engagementData.joinFee,
                "pot": 1,
                "isOneTime": 1
              }
              
              await checkAndPayOneTimeFee(feeParam)
              // if (!isClicked) {
              //   setIsClicked(true);
              //   if (fetchOneTimePay) {
              //     const coinParam = {
              //       "transactionType": ids.txnTypeTourneyFee,
              //       "description": `Entry fee in B: ${props.engagementData.title}`,
              //       "amount": -props.engagementData.joinFee,
              //       "deviceBalance": 0,
              //       "serverBalance": 0
              //     }
              //     await coinUse(coinParam)
              //   }
              // }
            }
            catch(e) {
              console.log(e)
            }
            props.onJoin();
          }
        }
      }
      else {
        props.onJoin();
      }
    }
  }

  return (
    <div style={styles.mainContainer}>
      {/* <div style={styles.arrowContainer} onClick={() => history("/")}>
        <img style={styles.backArrow} src={Arrow} alt="Arrow" />
      </div> */}
      <div style={styles.modalCircle}>
        <img style={styles.lockImage} src={isFree ? TourneyLogo : isPaid ? Unlock : Lock} alt="lock" />
      </div>
      <div style={styles.modalBox}>
        <div >
          <button onClick={() => props.onCancel()} style={styles.buttonCancelContainer}>
            X
          </button>  
        </div>
        <div style={styles.headerContainer}>
          { props.engagementData.topPlayers === 1 ? <div style={styles.headerText}>{`Rank 1 to win`}</div> :
            <div style={styles.headerText}>{`Rank 1 - ${props.engagementData.topPlayers} to win`}</div>
          }
        </div>
        <div style={styles.text}>{`Number of winners: ${props.engagementData.topPlayers}`}</div>
        <div style={styles.squareImageContainer}>
          <img style={styles.squareImage} src={props.engagementData.preloaderImageBig} alt="Egld" />
        </div>
        <div style={styles.scoreCounterContainer}>
          <label style={styles.priceText}>{"One-time entry free = "}</label>
          {isFree && <div style={styles.scoreContainer}>
            <label style={styles.scoreLabel}>FREE</label>
          </div>}
          {!isFree && isPaid && <div style={styles.scoreContainer}>
            <label style={styles.scoreLabel}>PAID</label>
          </div>}
          {!isFree && !isPaid && <div style={styles.scoreContainer}>
            <img style={styles.scoreImage} src={ScoreImage} alt="Score" />
            <label style={styles.amountLabel}>{formatNumberWithComma(props.engagementData.joinFee,0)}</label>
          </div>}
        </div>

        {/* <div style={styles.descriptionContainer}>
          <div />
          <label style={styles.description}>{" Use"}</label>
          <img style={styles.ticketImage} src={TicketImage} alt="Score" />
          <div />
        </div> */}
        <div style={styles.buttonContainer}>
          {/* <button style={styles.buttonCancelContainer} onClick={() => props.onCancel()}>
            Cancel
          </button> */}
          <button style={styles.buttonBuyContainer} onClick={() => onJoin()}>
            JOIN
          </button>
        </div>
      </div>
    </div>
  );
};

export default TourneyCover;
