import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import InnerPageHeader from "../../components/InnerPageHeader";
import { routes } from "../../constants";
import useStyles from "./styles";

const Tips = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const props = useLocation().state;

  useEffect(() => {
    window.onpopstate = () => {
      navigate(routes.home);
    };
  }, []);

  return (
    <>
      <div className={classes.container}>
        <InnerPageHeader
          hasBackBtn
          goBackClick={() => {
            navigate(routes.home);
          }}
          title={`B: ${props.title}`}
        />
        <img src={props.image} className={classes.homeContent} />
      </div>
    </>
  );
};

export default Tips;
