import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../utils/windowDimention';
import Loader from "../../components/Common/Loader";
import InnerPageHeader from '../../components/InnerPageHeader';
import Compress from 'compress.js';
import Placeholder from '../../assets/images/common-images/user_placeholder.png';
import packageJson from "../../../package.json";

import {
  getUserProfile,
  uploadMedia,
  updateAvatar
} from "../../services/ApiService";

const Profile = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({});
  const hiddenFileInput = React.useRef(null);

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getUserProfile();
      // console.log(`response=${JSON.stringify(response)}`)
      if (response) {
        setData(response.data.data);
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    fetchData();
  }, []);

  const resizeImage = async (file) => {
    // console.log(`file=${file}`)
    const compress = new Compress();
    const resizedImage = await compress.compress([file], {
      size: 2, // the max size in MB, defaults to 2MB
      quality: 1, // the quality of the image, max is 1,
      maxWidth: 405, // the max width of the output image, defaults to 1920px
      maxHeight: 405, // the max height of the output image, defaults to 1920px
      resize: true // defaults to true, set false if you do not want to resize the image width and height
    })
    const img = resizedImage[0];
    const base64str = img.data
    const imgExt = img.ext
    const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt)
    const newFile = await new File([resizedFiile], img, { type: img.type, });
    // console.log(`file=${newFile}`)
    return newFile;
  }

  const handleFile = async (file) => {
    const formData = new FormData();
    if (!file) {
      window.alert(`!File`);
      return;
    }
    formData.append("file", file);

    const response = await uploadMedia(formData);
    // console.log("response", JSON.stringify(response));
    try {
      const upload = await updateAvatar({avatarBig: response.data.data[0].imageUrlBigUrl, avatarSmall: response.data.data[0].imageUrlSmallUrl})
      if (upload) {
        // console.log(`upload.response=${JSON.stringify(upload)}`)
        await fetchData();
      }
    } catch (e) {
      console.log(e);
    }
  }

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    const mArray = fileUploaded.name.split(".");
    const fileExtension = mArray[mArray.length - 1];
    const allowedFileTypes = ["jpg", "png", "jpeg"];
    if (!allowedFileTypes.includes(fileExtension)) {
        window.alert(`File type not supported.`);
    }
    else {
      handleFile(fileUploaded);
    }
  };

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  return (
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      <div className={classes.homeContainer}>
        <div style={{ width: '100%', alignItems: 'center', maxWidth: 480, minWidth: 480, }}>
          <div className={classes.avatarContainer} onClick={handleClick}>
            <img src={data.avatarBig ? data.avatarBig : Placeholder} className={classes.avatar} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{display: 'none'}} // Make the file input element invisible 
            />
          </div>
        </div>
        <div style={{position: 'absolute', top: 0, paddingLeft: 20, paddingRight: 20, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
          <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="Profile" style={{position: 'absolute'}}/>
        </div>
        <p className={classes.name}>{data.displayName}</p>
        <p className={classes.address}>{localStorage.getItem("wefftWalletAddress")}</p>
        <p style={{ color: "#000", marginTop: 50 }}>
          {process.env.REACT_APP_VERSION_PREFIX} {packageJson.version}
        </p>
      </div>
    </div>
  );
};

export default Profile;
