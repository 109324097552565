import React, { useEffect, useState } from "react";
import "./styles.css";
import { logout, useGetAccountInfo } from "@elrondnetwork/dapp-core";

import { checkWalletAddress, checkName, register, login } from "../../services/ApiService";
import { routes } from "../../constants";
import { useNavigate } from "react-router-dom";
import NoEffort from '../../assets/images/common-images/no_effort.png';
import { effortHelper } from "../../helpers/effortHelper";

import useStyles from './style';
import { checkNft } from "../../utils/utils";
import { nftHelper } from "../../helpers/nftHelper";

const MaiarPage = () => {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const classes = useStyles();
  const [heHasNft, setHeHasNft] = useState(false);

  const loginHandler = async (oldId, apiKey, address) => {
    try {
      const response = await login({
        oldId,
        apiKey,
      });
      if (response) {
        // console.log("Login Response => ", response.data);
        localStorage.setItem(
          "token",
          JSON.stringify(response.headers.authorization)
        );
        localStorage.setItem("wefftWalletAddress", address);
        localStorage.setItem("footerIndex", 1);
        localStorage.setItem("itemIndex", 0);
        localStorage.setItem("oldId", oldId);
        if (localStorage.getItem("token") && JSON.parse(localStorage.getItem("token"))) {
          navigate(routes.home);
        }
        else {
          // navigate(routes.login);
        }
      }
    } catch (e) {
      console.log(e);
      // navigate(routes.login);
    }
  };

  useEffect(async () => {
    const address = localStorage.getItem("maiarAddress");
    // console.log("Maiar Address", address);
    const creators = [ //check in homepage
      "erd1qf9et8d4swd09unnv0rdzezpkyxg5f2tk00tlr039nhjyz8xrm5qfj53ww", //elva
      "erd1jpr49da65vae0wjl73e3ghv443swxqaqvacu2f6uelvcnuxxw5jqktqsyh", //elrond lion
      "erd1qqqqqqqqqqqqqpgqeys5lt360l342m4dk2edehykktwn34djys5s46rk5f", //evoluzion
      "erd14edl4fvr8wc2sczrz5af6tfmfucgqzsh4u73hnxw96l07cekdg6schwtsh", //maiarpunks
      "erd1qqqqqqqqqqqqqpgqf6qde2f9dt8u943la9ehgvemzldx7rccxjhs3nqucu", //superrarebears
      "erd18e7t3fquqjm9rs6385tqu0pk543e43e44rlkrhtdzfd64rgu7jrq6jrza9", //whaley
    ];
    let hasNft = false;
    creators.every(async (creator) => {
      const data = {
        user_wallet: address,
        creator_wallet: creator,
      };
      const nft = await nftHelper(data);
      // console.log(`nft=${JSON.stringify(nft)}`)
      if (nft.length > 0) {
        hasNft = true;
        setHeHasNft(true);
        try {
          const response = await checkWalletAddress({walletAddress: address});
          if (response) {
            if (response.data.result) {
              loginHandler(response.data.data.oldId, response.data.data.apiKey, address)
              try {
          const response = await checkWalletAddress({walletAddress: address});
          if (response) {
            if (response.data.result) {
              loginHandler(response.data.data.oldId, response.data.data.apiKey, address)
              return;
            }
            else {
              const min = 4;
              const max = 10;
              const rand = min + Math.random() * (max - min);
              let name = address.slice(address.length - rand, address.length)
              const response1 = await checkName({displayName: name});
              if (response1) {
                if (response1.data.result) {
                  name = address.slice(address.length - rand - 1, address.length)
                }
                const response2 = await register({displayName: name, walletAddress: address});
                if (response2) {
                  loginHandler(response2.data.data.oldId, response2.data.data.apiKey, address)
                }
                else {
                  console.log('3');
                  // navigate(routes.login);
                }
              }
              else {
                console.log('4');
                // navigate(routes.login);
              }
            }
          }
          else {
            console.log('5');
            // navigate(routes.login);
          }
        } catch (e) {
          console.log(e);
          // navigate(routes.login);
        }
            }
            else {
              const min = 4;
              const max = 10;
              const rand = min + Math.random() * (max - min);
              let name = address.slice(address.length - rand, address.length)
              const response1 = await checkName({displayName: name});
              if (response1) {
                if (response1.data.result) {
                  name = address.slice(address.length - rand - 1, address.length)
                }
                const response2 = await register({displayName: name, walletAddress: address});
                if (response2) {
                  loginHandler(response2.data.data.oldId, response2.data.data.apiKey, address)
                  return;
                }
                else {
                  console.log('3');
                  // navigate(routes.login);
                }
              }
              else {
                console.log('4');
                // navigate(routes.login);
              }
            }
          }
          else {
            console.log('5');
            // navigate(routes.login);
          }
        } catch (e) {
          console.log(e);
          // navigate(routes.login);
        }
      }
      return true;
    });
    const EFFORT_CHECK = 0;
    const effort = await effortHelper({
      user_wallet: address,
    });
    // console.log(`effort=${effort}; hasNft=${hasNft}`)
    if (effort == 0 && !hasNft) {
      setShowDialog(true);
    }
    else {
      if (localStorage.getItem("token") && JSON.parse(localStorage.getItem("token"))) {
        //do nothing
      }
      else {
        try {
          const response = await checkWalletAddress({walletAddress: address});
          if (response) {
            if (response.data.result) {
              loginHandler(response.data.data.oldId, response.data.data.apiKey, address)
            }
            else {
              const min = 4;
              const max = 10;
              const rand = min + Math.random() * (max - min);
              let name = address.slice(address.length - rand, address.length)
              const response1 = await checkName({displayName: name});
              if (response1) {
                if (response1.data.result) {
                  name = address.slice(address.length - rand - 1, address.length)
                }
                const response2 = await register({displayName: name, walletAddress: address});
                if (response2) {
                  loginHandler(response2.data.data.oldId, response2.data.data.apiKey, address)
                }
                else {
                  console.log('3');
                  // navigate(routes.login);
                }
              }
              else {
                console.log('4');
                // navigate(routes.login);
              }
            }
          }
          else {
            console.log('5');
            // navigate(routes.login);
          }
        } catch (e) {
          console.log(e);
          // navigate(routes.login);
        }
      }
    }
  }, [])

  const addressMaiar = localStorage.getItem("maiarAddress");
  console.log("Maiar Address", addressMaiar);
  const { address } = useGetAccountInfo();

  useEffect(() => {
    if (address) {
      setTimeout(() => {
        logout(`${window.location.origin}/maiar`);
      }, 2000);
      // localStorage.removeItem("walletconnect");
    }
  }, []);

  return (
    <>
      <div className="container">
        <div style={{ color: "#ffffff" }}>
          {/* <h2>Maiar Address</h2>
          {addressMaiar && addressMaiar} */}
        </div>
        { (showDialog && !heHasNft) && 
          <div className={classes.tutorialContainer} onClick={() => {navigate(routes.login)}}>
            <img src={NoEffort} className={classes.tutorialImage}/> 
          </div>
        }
      </div>
    </>
  );
};

export default MaiarPage;
