import React, { useEffect, useState } from 'react';
import ProfileIcon from '../ProfileIcon';
import ScoreCounter from '../ScoreCounter';
import Ticket from '../Ticket';
import useStyles from './styles';

import CryptoJS from 'crypto-js';

import {
  getUserBalance
} from "../../services/ApiService";

const Header = (props) => {
  const classes = useStyles();
  const [balance, setBalance] = useState(0);
  const [tickets, setTickets] = useState(0);

  useEffect(() => {
    fetchGetBalance();
  }, [])

  const fetchGetBalance = async () => {
    try {
      const response = await getUserBalance();

      // console.log("response", JSON.stringify(response));
      if (response) {
        // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(response.data.data), process.env.REACT_APP_ENCRYPT_KEY).toString();
        localStorage.setItem(
          "balance",
          JSON.stringify(response.data.data)
        );
        setBalance(response.data.data.coinAmount);
        setTickets(response.data.data.ticketQuantity);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={classes.headerContainer}>
      <ScoreCounter balance={balance}/>
      <div className={classes.ticketProfileContainer}>
        {/* <Ticket balance={tickets}/> */}
        <ProfileIcon setSidebarOpen={props.setSidebarOpen} avatar={props.avatar} notificationCount={props.notificationCount}/>
      </div>
    </div>
  );
};

export default Header;
