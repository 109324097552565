import React, { useState } from "react";
import useStyles from "./styles";
import { footerMenus } from "../../utils/footerMenu";
import useWindowDimensions from "../../utils/windowDimention";

import "./style.css";

const Footer = (props) => {
  const classes = useStyles();
  const [selectedMenu, setSelectedMenu] = useState(props.index);
  const { height, width } = useWindowDimensions();

  const styles = {
    mainDiv: {
      position: "fixed",
      width: "480px",
      paddingLeft: "40px",
      paddingRight: "40px",
      // top: height - 95,
      bottom: -18,
      paddingTop: 15,
      paddingBottom: 35,
      display: "flex",
      justifyContent: "space-between",
      zIndex: 1,
      background:
        "linear-gradient(184deg, rgba(20,20,20,0.1) 0%, rgba(20,20,20,0.3) 0%, rgba(20,20,20,0.7) 100%)",
    },
  };

  return (
    <div className="bottom_navigation_content" style={styles.mainDiv}>
      {footerMenus.map((item, index) => (
        <div
          key={`item${index}`}
          className={classes.footerMenuContainer}
          onClick={() => {
            setSelectedMenu(index);
            props.setIndex(index);
          }}
        >
          <img
            className={classes.menuIcon}
            src={props.index == index ? item.icon : item.iconWhite}
            alt={item.label}
            style={{ width: index === 1 ? 44 : 30, alignItems: "flex-end" }}
          />
          <label
            className={classes.menuLabel}
            style={{ fontSize: index === 1 ? 12 : 10 }}
          >
            {item.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default Footer;
