import { Navigate, Outlet } from "react-router-dom";
import LoginPage from "./pages/Login/index";
import { routes } from "../src/constants";
import HomePage from "./pages/Home/index";
import GetLeaderboards from "./pages/GetLeaderboards";
import PostLeaderboard from "./pages/PostLeaderboard";
import UploadMedia from "./pages/UploadMedia";
import FunTypeRunner from './pages/FunTypeRunner';
import Broadcast from './pages/Broadcast';
import Boost from './pages/Boost';
import BoostDetails from './pages/Boost/Details';
// import GameLoader from "./pages/FunTypeRunner/gameLoader";
import ScratchCard from "./pages/scratchCard";
import ScratchWin from "./pages/scratchWin";
import Tips from "./pages/Tips";
import Profile from './pages/Profile';
import Notification from "./pages/Notification";
import History from "./pages/History";
import Winners from "./pages/History/Winners";
import Famers from './pages/Famers';
import Leaderboard from './pages/Leaderboard';
import UnlockRoute from "./components/UnlockPage";
import HLLeaders from "./pages/HLLeaders";
import Collection from "./pages/Collection";
import CollectionDetails from "./pages/Collection/Details";
import MaiarPage from "./components/MaiarPage";

const getRoutes = (user) => [
  {
    path: routes.homepage,
    element: !user ? <Outlet /> : <Navigate to={routes.home} />,
    children: [
      { path: routes.login, element: <LoginPage /> },

      { path: routes.homepage, element: <Navigate to={routes.login} /> },
      { path: routes.unlock, element: <UnlockRoute /> },
      { path: routes.maiar, element: <MaiarPage /> },
    ],
  },
  {
    path: routes.homepage, element: user ? <Outlet /> : <Navigate to={routes.login} />,
    children: [
      { path: routes.home, element: <HomePage /> },
      // { path: routes.gameLoader, element: <GameLoader /> },
      { path: routes.funtyperunner, element: <FunTypeRunner /> },
      { path: routes.leaderboards, element: <GetLeaderboards /> },
      { path: routes.postLeaderboard, element: <PostLeaderboard /> },
      { path: routes.uploadMedia, element: <UploadMedia /> },
      { path: routes.broadcast, element: <Broadcast /> },
      { path: routes.boost, element: <Boost /> },
      { path: routes.boostDetails, element: <BoostDetails /> },
      { path: routes.scratchcardpage, element: <ScratchCard /> },
      { path: routes.scratchwinpage, element: <ScratchWin /> },
      { path: routes.tips, element: <Tips />},
      { path: routes.profile, element: <Profile />},
      { path: routes.notification, element: <Notification />},
      { path: routes.history, element: <History />},
      { path: routes.winners, element: <Winners /> },
      { path: routes.famers, element: <Famers /> },
      { path: routes.leaderboard, element: <Leaderboard /> },
      { path: routes.unlock, element: <UnlockRoute /> },
      { path: routes.hlLeaders, element: <HLLeaders /> },
      { path: routes.collection, element: <Collection /> },
      { path: routes.collectionDetails, element: <CollectionDetails /> },
    ],
  },
];

const elrondRoutes = [
  {
    path: routes.home,
    title: 'Home',
    component: HomePage
  },
  {
    path: routes.login,
    title: 'Login',
    component: LoginPage,
    authenticatedRoute: true
  }
];

export default getRoutes;
