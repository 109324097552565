import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import useStyles from './styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import GameImage from '../../assets/images/game.jpeg';
import useWindowDimensions from '../../utils/windowDimention';
import Loader from "../../components/Common/Loader";
import InnerPageHeader from '../../components/InnerPageHeader';
import Famer from '../../assets/images/history/famer.png';
import Jackpot from '../../assets/images/history/jackpot.png';
import Battle from '../../assets/images/history/challenge.png';
import Tourney from '../../assets/images/history/tourney.png';
import Boost from '../../assets/images/history/boost.png';
import Attack from '../../assets/images/history/attack_log.png';
import Defend from '../../assets/images/history/defend_log.png';
import Coin from '../../assets/images/history/bengga_coin.png';
import Store from '../../assets/images/history/store_bought.png';
import Ticket from '../../assets/images/history/ticket.png';
import RAF from '../../assets/images/history/raf.png';
import Gateway from '../../assets/images/history/gateway.png';
import Charm from '../../assets/images/history/charm.png';
import Highlight from '../../assets/images/history/highlight.png';
import moment from "moment";
import { formatNumberWithComma } from '../../utils/utils';
import { routes } from '../../constants';

import {
  getCoinHistory
} from "../../services/ApiService";

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const History = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const props = useLocation().state;
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getCoinHistory();
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response) {
        setData(response.data.data);
        setLoader(false);
      }

      // console.log("response", response);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  return (
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      <div className={classes.homeContainer}>
        <div className={classes.titleContainer}>
          <p className={classes.name}>{props.userData.displayName}</p>
          <p className={classes.title}>Balance</p>
        </div>
        <div style={{ width: '100%', alignItems: 'center', maxWidth: 480, marginTop: '0px' }}>
          {data.length > 0 && data.map((item,index) => {
              let image;
              switch(item.transactionType) {
                case 1: case 2:
                  image = Famer;
                  break;
                case 3: case 4:
                  image = Jackpot;
                  break;
                case 5: case 6: case 7: case 8:
                  image = Battle; 
                  break;
                case 9: case 10:
                  image = Tourney;  
                  break;
                case 11:
                  image = Boost;
                  break;
                case 12: 
                  image = Attack;
                  break;
                case 13: 
                  image = Defend;
                  break;
                case 14: case 17:
                  image = Coin;
                  break;
                case 15:
                  image = Store;
                  break;
                case 16: 
                  image = Ticket;
                  break;
                case 18:
                  image = RAF;
                  break;  
                case 19:
                  image = Gateway;
                  break;  
                case 20:
                  image = Charm;
                  break;  
                case 21: case 22: case 23: case 24:
                  image = Highlight;
                  break;  
                default:
              }
              return (<div className={classes.historyContainer} key={item.lastModifiedDate} style={{backgroundColor: index % 2 === 0 ? '#303030' : 'transparent'}}>
                <img src={image} className={classes.profileImage} />
                <div>
                  <p className={classes.description}>{item.description}</p>
                  <p className={classes.lastmoddate}>{moment(item.lastModifiedDate).format("YYYY-MM-DD HH:mm:ss")}</p>
                </div>
                <p className={item.amount > 0 ? classes.plus : classes.minus }>{item.amount > 0 ? '+' : ''}{formatNumberWithComma(item.amount, 0)}</p>
                <p className={classes.amount }>{formatNumberWithComma(item.serverBalance,0)}</p>
              </div>)}
            )
            }
        </div>
        <div style={{position: 'absolute', top: 0, paddingLeft: 20, paddingRight: 20, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
          <InnerPageHeader hasBackBtn hasWinnersBtn goBackClick={() => navigate(-1)} title="Transaction History"  onWinnerPress={() => navigate(routes.winners)} style={{position: 'absolute'}}/>
        </div>
      </div>
    </div>
  );
};

export default History;
