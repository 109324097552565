import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    maxWidth: '480px',
    width: '100%',
    padding: '0 20px',
    minHeight: '100vh',
    height: 'auto',
    position: 'relative',
    background: '#000',
    margin: '0 auto'
  },
  scratchWinContent: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 40px)',
    height: 'auto'
  },
  scratchWinSmallImageBox: {
    border: '2px solid #379ea3',
    borderRadius: '100px',
    width: '70px',
    height: '70px',
    background: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px auto 0'
  },
  scratchWinMuchText: {
    color: '#fff',
    fontSize: '15px',
    fontWeight: 500,
    marginTop: '30px',
    display: 'block',
    textAlign: 'center'
  },
  scratchWinCoinImgText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px'
  },
  scratchWinCoinImg: {
    width: '30px',
    height: '30px',
    marginRight: '10px'
  },
  scratchWinCoinText: {
    fontSize: '31px',
    color: '#03abbd',
    margin: 0
  },
  scratchWinProductImage: {
    width: 280,
    height: 280,
    objectFit: 'contain'
  },
  scratchCardContent: {
    border: '0px solid #345580',
    overflow: 'hidden',
    borderRadius: '6px',
    maxWidth: '280px',
    width: '100%',
    margin: '20px auto'
  },
  scratchRevealText: {
    color: '#ddd',
    marginTop: 'auto',
    marginBottom: '75px',
    fontSize: '16px',
    fontWeight: 500
  },
  scratchWinCoinImage: {
    height: '100%',
    objectFit: 'cover',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  },
  scratchContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute'
  },
  coin: {
    fontSize: 36,
    color: '#00acb8',
    width: '280px',
    height: 40,
    marginTop: '125px',
    marginLeft: 10
  },
}));
