import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Common/Loader";
import { routes } from "../../constants";
import { login } from "../../services/ApiService";
import Logo from "../../assets/images/logo/main_logo.png";
import Beer from "../../assets/images/footer-images/beer.png";
import { getIsLoggedIn, DappUI } from "@elrondnetwork/dapp-core";
import Sound from "../../assets/sounds/welcome.mp3";
import {
  isMobile,
  browserName,
  isAndroid,
  isIOS,
  isChrome,
  isSafari,
} from "react-device-detect";
import "./style.css";

import "./styles.css";
import Maiar from "../../assets/images/logo/maiar.png";

const LoginPage = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { WalletConnectLoginButton } = DappUI;

  const isLoggedIn = getIsLoggedIn();
  useEffect(() => {
    if (isLoggedIn) {
      console.log("loggg", isLoggedIn);
      // localStorage.removeItem("persist:dapp-core-store");
      const user =
        localStorage.getItem("token") &&
        JSON.parse(localStorage.getItem("token"));
      if (user) {
        // window.location.href = routes.home;
        navigate(routes.home);
      }
    }
  }, [isLoggedIn]);

  const loginHandler = async (oldId, apiKey, wefftWalletAddress) => {
    try {
      setLoader(true);
      const response = await login({
        oldId,
        apiKey,
      });
      if (response) {
        setLoader(false);
        // console.log("Login Response => ", response.data);
        localStorage.setItem(
          "token",
          JSON.stringify(response.headers.authorization)
        );
        localStorage.setItem("wefftWalletAddress", wefftWalletAddress);
        localStorage.setItem("maiarAddress", wefftWalletAddress);
        localStorage.setItem("footerIndex", 1);
        localStorage.setItem("itemIndex", 0);
        localStorage.setItem("oldId", oldId);
        navigate(routes.home);
        // window.open(routes.home);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        minHeight: "100vh",
        flexDirection: "column",
      }}
    >
      {loader && <Loader />}
      <img
        src={Logo}
        style={{
          width: 60,
          height: 60,
          objectFit: "cover",
          borderRadius: "50%",
          marginBottom: 20,
          marginTop: 20,
        }}
      />
      <img
        src={Beer}
        style={{
          width: 240,
          height: 240,
          objectFit: "cover",
          marginBottom: 30,
        }}
      />
      <p
        style={{
          color: "#00acb8",
          marginTop: 0,
          fontFamily: "CanaroMedium",
          fontSize: 40,
        }}
      >
        B.E.E.R.
      </p>
      {/*<button
        onClick={() =>
          loginHandler(
            "30485860-caf6-11e3-91ec-123139260fba",
            "Jy4CDgqpoISgOgl5soDtHn9gaFtTCGS1",
            "erd1e2rfnsg2dlqsaf5q7qmeweuxkt8rshe2jdnwce87rend4crax0vq0nrzu7"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "red",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1646883153360.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 1
      </button>
      <button
        onClick={() =>
          loginHandler(
            "92d3ae60-725e-11ea-89b2-0242ac110008",
            "KgbJBRPxj5IvwYUy898DW4FUcmyrPatR",
            "erd1ht8kvgxmjxvy8wjhnzsenje0l26sn2pzwrfhmw3yjhyy5fk23cpqferrnl"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "green",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648431537516.png"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 2
      </button>
      <button
        onClick={() =>
          loginHandler(
            "ec3d5250-87ba-11ea-9ac1-0242ac110008",
            "zmEXpN61E9yddyyc5gvtCfieCeFK8qX4",
            "erd1y797wexf3a38u7g64368yydgewpxheywwl3k5c3anta2297kruaq28qtpj"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "purple",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.cdn.digitaloceanspaces.com/images/720/F373360622432ICIS2W.jpg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 3
      </button>
      <button
        onClick={() =>
          loginHandler(
            "c0c0dfc0-eead-11ea-a413-0242ac11000c",
            "bzkJwI3w80D5H24qmcccS8SjWbM0tvTc",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6ygzlszae7sn"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "blue",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1649234619134.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 4
      </button>
      <button
        onClick={() =>
          loginHandler(
            "5ec02ed0-7553-11ea-abd2-0242ac110008",
            "NYFQDQSvx4RPbrV9O9StkHY0ng0aaY2l",
            "erd1e2rfnsg2dlqsaf5q7qmeweuxkt8rshe2jdnwce87rend4crax0vq0dg63j"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "purple",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648694947840.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 5
      </button>
      <button
        onClick={() =>
          loginHandler(
            "2d1d3420-d9c7-11eb-904b-86daee0498db",
            "LmFGiAnqW9qnvlDMSBsIqrSPIvNYWVhi",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6ygzlszvxlc6"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648695349686.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 6
      </button>
      <button
        onClick={() =>
          loginHandler(
            "0060a390-b659-11eb-8e20-924d266ce740",
            "Jz2vFofki3wjBLbOmBwuQz1tlw9lI9GT",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzntpuglk"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1646883153360.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 7
      </button>
      <button
        onClick={() =>
          loginHandler(
            "00614020-f470-11eb-bf89-aa9e76aedffd",
            "iJjI7W3X7BIXRAcGisD7GmtY2ANtCkWl",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzwquec6r"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648431537516.png"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 8
      </button>
      <button
        onClick={() =>
          loginHandler(
            "00614400-d880-11eb-a293-3ad15f7fd6a1",
            "YLmi8Ne0J2PzsrsqrRWXLHOPbRqsudXb",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzuencpeh"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.cdn.digitaloceanspaces.com/images/720/F373360622432ICIS2W.jpg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 9
      </button>
      <button
        onClick={() =>
          loginHandler(
            "00615960-dcc5-11eb-9892-168b6e73f4be",
            "wxl61427Jr7dmqw1DtO3oUNCn1VxKF2s",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzze4x1du1"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1649234619134.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 10
      </button>
      <button
        onClick={() =>
          loginHandler(
            "0061cbb0-f8aa-11eb-8827-d292e928ff1d",
            "d96ZjgUTRmTsZnhpoDPqMm01gR2p5B5u",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzhvx1gwu"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648694947840.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 11
      </button>
      <button
        onClick={() =>
          loginHandler(
            "0061d8d0-0057-11ec-997b-12ddffe82d3a",
            "lqfbDUfGmVgz8n8Fobkba8dpG6bcPbZv",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzxnskqqd"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648695349686.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 12
      </button>
      <button
        onClick={() =>
          loginHandler(
            "00629880-ba00-11eb-b709-4a5ab3aa9b52",
            "fB6mbNkRZKTkq5d4ja2cktjSPgfouTa2",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzvxichqo"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1646883153360.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 13
      </button>
      <button
        onClick={() =>
          loginHandler(
            "0062e2d0-fead-11eb-9ead-faac3a0948c6",
            "omcDM06dA9DmUoA8A6HC10nsJKM7bolz",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzhw76p9r"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648431537516.png"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 14
      </button>
      <button
        onClick={() =>
          loginHandler(
            "0063cde0-ca4f-11eb-a3a5-427dfbda9bef",
            "0i7TnAAg6vP4IekkRWGsaWdeWKwqHSaI",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzbrt5unw"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.cdn.digitaloceanspaces.com/images/720/F373360622432ICIS2W.jpg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 15
      </button>
      <button
        onClick={() =>
          loginHandler(
            "00649e60-c774-11eb-ba14-aeab16f6b412",
            "yPDZVZQ52hENYqCQ1ohYWNUv3k5Dl1a6",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzcmfuy8l"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1649234619134.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 16
      </button>
      <button
        onClick={() =>
          loginHandler(
            "0064eec0-f2b1-11eb-8a95-36e6d441953c",
            "cOzlYw48fd5CPgteSncHqkq6kkfLg2gQ",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzk6mcigw"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648694947840.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 17
      </button>
      <button
        onClick={() =>
          loginHandler(
            "0064fb70-ec32-11eb-897a-4618411deb55",
            "qhLjhSehIS1L17pmZeNCT2dkvz2zSFs9",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzziyu8buo"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648695349686.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 18
      </button>
      <button
        onClick={() =>
          loginHandler(
            "006531e0-f32a-11eb-8a95-36e6d441953c",
            "pQLXGeEoLMm38vWSTuVwafEcdoBfnLIW",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzvhhrvah"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1646883153360.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 19
      </button>
      <button
        onClick={() =>
          loginHandler(
            "0066f580-e938-11eb-897a-4618411deb55",
            "wHAKCf26z3klsPVgS364lKWftanEU2kV",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzhvetw7a"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648431537516.png"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 20
      </button>
      <button
        onClick={() =>
          loginHandler(
            "00670b30-f0fc-11eb-993a-e6771cdb9087",
            "zcrzDsmgU3gKGUmQLMbWe3hrvvuRyuVh",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzwvp1swo"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.cdn.digitaloceanspaces.com/images/720/F373360622432ICIS2W.jpg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 21
      </button>
      <button
        onClick={() =>
          loginHandler(
            "0067ccf0-c235-11eb-abce-6250ee276857",
            "nlcXtzoHk2wVMMFnsSUVHRcKlfjezaRZ",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzz2ddufbj"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1649234619134.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 22
      </button>
      <button
        onClick={() =>
          loginHandler(
            "00681550-d507-11ea-a912-0242ac110010",
            "UQcmq07zkLYqBV3FadeYkEcyn8miyZNV",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzznq782aa"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648694947840.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 23
      </button>
      <button
        onClick={() =>
          loginHandler(
            "00681a50-e3cf-11eb-a293-3ad15f7fd6a1",
            "oyFkSBgVVuAGWXomH5EDRYRksycBTUKE",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzs3fgsot"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648695349686.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 24
      </button>
      <button
        onClick={() =>
          loginHandler(
            "0068dfd0-c903-11eb-9d74-befc22f07fe8",
            "TldF5NUYFUJfrRMU7je9HoRWbtSkafBj",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzulk4uea"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1646883153360.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 25
      </button>
      <button
        onClick={() =>
          loginHandler(
            "0069a090-d968-11ea-8eb3-0242ac110002",
            "2sjeoR7wjWXgn27ynx3AHXYYlfcPkSCK",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzz59vpgbp"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648431537516.png"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 26
      </button>
      <button
        onClick={() =>
          loginHandler(
            "006a3e60-d7b4-11eb-a293-3ad15f7fd6a1",
            "pE2ufvuFRBJPJqTnzWhB0ZqtEaLn7m1N",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzukofhpc"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.cdn.digitaloceanspaces.com/images/720/F373360622432ICIS2W.jpg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 27
      </button>
      <button
        onClick={() =>
          loginHandler(
            "006ae5d0-bef6-11eb-be7f-06bb0ef06991",
            "r872aJkFYdsK2Nc7uWTYdw1DOnKuXDWv",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzzkrasq3x"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1649234619134.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 28
      </button>
      <button
        onClick={() =>
          loginHandler(
            "006b4360-c52a-11eb-bfdd-7ad97bb9ed3b",
            "u0bwTX6GxaobNj473zhKwuLKuyz6DBPX",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzz52115j2"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648694947840.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 29
      </button>
      <button
        onClick={() =>
          loginHandler(
            "006c0380-d3cc-11eb-9892-168b6e73f4be",
            "Yucg8ayiUsqgvCNKdnZt27lj0xMeGxm4",
            "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6yzzz3vpsj90"
          )
        }
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        <img
          src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648695349686.jpeg"
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
        Player 30
      </button> */}
      {/* <button
        onClick={() => {
          console.log("here");
          navigate(routes.unlock);
        }}
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        Wallet
      </button> */}
      <div style={{ marginTop: 20, marginBottom: 10 }}>
        <WalletConnectLoginButton
          callbackRoute={routes.maiar}
          loginButtonText={`Connect to Maiar`}
          logoutRoute={routes.maiar}
          buttonClassName="button_maiar"
        />
        <img
          src={Maiar}
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            position: "relative",
            left: 35,
            bottom: 36,
          }}
        />
      </div>
      <p style={{ color: "#db3eb1", marginTop: 0 }}>
        {process.env.REACT_APP_VERSION_PREFIX} {process.env.REACT_APP_VERSION}
      </p>
      {/* <iframe src={Sound}
        frameborder='0'
        allow='autoplay; encrypted-media'
        style={{width: 0, height: 0}}
        title='video'
        playsInLine
      /> */}
      <video
        muted={false}
        playsInline
        preload="auto"
        src={Sound}
        loop
        autoPlay
        alt="icon"
        style={{ width: 0, height: 0 }}
      />
      {!isMobile && (
        <p
          style={{
            color: "white",
            fontSize: 13,
            marginTop: 5,
            fontFamily: "CanaroBook",
          }}
        >
          *Best viewed on mobile browser
        </p>
      )}
      {isMobile && isIOS && !isSafari && (
        <p
          style={{
            color: "white",
            fontSize: 13,
            marginTop: 5,
            fontFamily: "CanaroBook",
          }}
        >
          *Best viewed on Safari
        </p>
      )}
      {isMobile && isAndroid && !isChrome && (
        <p
          style={{
            color: "white",
            fontSize: 13,
            marginTop: 5,
            fontFamily: "CanaroBook",
          }}
        >
          *Best viewed on Chrome
        </p>
      )}
    </div>
  );
};

export default LoginPage;
