import React from 'react';
import useStyles from './styles';
import HowtojoinImage from '../../assets/images/howtojoin.jpg';

const DetailJoin = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.detailJoinContainer}>
      <div className={classes.detailJoinTitle}>
        How to join <i className="fa fa-chevron-down fa-lg" aria-hidden="true" ></i>
      </div>
      <img className={classes.howtojoinImage} src={props.image} alt='invite' />
    </div>
  );
};

export default DetailJoin;