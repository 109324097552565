import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'
import Header from '../../components/BoostHeader';
import useStyles from './styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import ComponentData from '../../components/ComponentData';
import GameImage from '../../assets/images/game.jpeg';
import useWindowDimensions from '../../utils/windowDimention';
import Loader from "../../components/Common/Loader";
import PageHeader from '../../components/InnerPageHeader';
import BoostHeader from '../../components/BoostHeader';
import { formatNumberWithComma, msToShortenedTimeLeft, getTimeLeft } from '../../utils/utils';

import {
  getBoosts
} from "../../services/ApiService";

import { routes } from "../../constants";

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Home = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [loader, setLoader] = useState(false);
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [data, setData] = useState([]);
  const [timeElapsed, setTimeElapsed] = useState(0);

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getBoosts();
      // console.log(`response=${JSON.stringify(response)}`)
      if (response) {
        setData(response.data);
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const styles = {
    mainDiv: {
      position: 'absolute',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      top: height - 32,
    },
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeElapsed(timeElapsed + 1)
    }, 1000);
  
    return () => clearTimeout(timer);
  });

  return (
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      <div className={classes.homeContainer}>
        <div style={{ width: '100%', alignItems: 'center', maxWidth: 480 }}>
          <div style={{ width: '100%', alignItems: 'center', maxWidth: 480 }}>
          <BoostHeader 
            description={data.length > 0 && data[selectedSlide].MitApp.description}
            avatar={data.length > 0 && data[selectedSlide].MitApp.User.avatar_big}
            timeLeft={data.length > 0 && msToShortenedTimeLeft( (new Date > new Date(data[selectedSlide].start_date)) ? getTimeLeft(data[selectedSlide].expiry_date) : getTimeLeft(data[selectedSlide].start_date) - timeElapsed, 'Expired')}
            balance={data.length > 0 && (data[selectedSlide].MitApp.is_crypto == 1 ? formatNumberWithComma(data[selectedSlide].MitApp.MitAppPrize.crypto_amount, 4) : data[selectedSlide].MitApp.MitAppPrize.amount)}
            isCrypto={data.length > 0 && data[selectedSlide].MitApp.is_crypto}
            amount={data.length > 0 && (data[selectedSlide].MitApp.is_crypto ? data[selectedSlide].MitApp.MitAppPrize.crypto_amount : data[selectedSlide].MitApp.MitAppPrize.amount)}
            cryptoImage={data.length > 0 && data[selectedSlide].MitApp.crypto_image_big }
          />
            <Carousel
              emulateTouch={true}
              showArrows={false}
              showThumbs={false}
              showStatus={false}
              axis={'vertical'}
              showIndicators={false}
              dynamicHeight={true}
              // onClickItem={(index) => {
              //   navigate('/boostDetails',{state: { boostData: data[index] }})
              // }}
              onChange={(item) => {
                setSelectedSlide(item)
              }}
            >
              {data.length > 0 && data.map((item,index) => 
                <Link
                  to={{ pathname: routes.boostDetails }}
                  state={{ boostDetails: item}}
                  style={{ height: height, display: "block" }}
                  key={item.broadcastId}
                >
                  {<img
                    src={item.MitApp.cover_image_big}
                    className={classes.homeContent}
                  />}
                  <div style={styles.mainDiv}>
                    <label className={classes.dataLabel}>{item.MitApp.title}</label>
                  </div>
                </Link>)
              }
              {/* <div style={{ height: height }}>
                <img className={classes.homeContent} src={GameImage} />
              </div>
              <div style={{ height: height }}>
                <img className={classes.homeContent} src={GameImage} />
              </div> */}
            </Carousel>
            <div className={classes.sliderIndicatorContainer}>
              {data.length > 1 && data.map((slide, index) => (
                <div
                  key={`item${index}`}
                  style={{
                    width: selectedSlide === index ? 10 : 7,
                    height: selectedSlide === index ? 10 : 7,
                    background: selectedSlide === index ? '#CD49B8' : 'transparent',
                  }}
                  className={classes.sliderIndicator}
                  onClick={() => setSelectedSlide(index)}
                />
              ))}
            </div>
          </div>
          {/* <img className={classes.homeContent} src={GameImage} /> */}
        </div>
        <div style={{position: 'absolute', top: 0, paddingLeft: 20, paddingRight: 40, alignItems: 'center', width: '100%', backgroundColor: 'black'}}>
          <PageHeader hasBackBtn goBackClick={() => navigate(-1)} title={'Boost'} style={{position: 'absolute'}}/>
        </div>
      </div>
    </div>
  );
};

export default Home;
