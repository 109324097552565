import Famer from '../assets/images/footer-images/famer.png';
import FamerWhite from '../assets/images/footer-images/famer-white.png';
import Jackpot from '../assets/images/footer-images/jackpot.png';
import JackpotWhite from '../assets/images/footer-images/jackpot-white.png';
import Highlight from '../assets/images/footer-images/highlight.png';
import HighlightWhite from '../assets/images/footer-images/highlight-white.png';
import Tourney from '../assets/images/footer-images/tourney.png';
import TourneyWhite from '../assets/images/footer-images/tourney-white.png';
import Beer from '../assets/images/footer-images/beer.png';
import BeerWhite from '../assets/images/footer-images/beer-white.png';
export const footerMenus = [
  {
    label: 'Famer',
    icon: Famer,
    iconWhite: FamerWhite,
  },
  // {
  //   label: 'Jackpot',
  //   icon: Jackpot,
  //   iconWhite: JackpotWhite,
  // },
  {
    label: 'BEER',
    icon: Beer,
    iconWhite: BeerWhite,
  },
  {
    label: 'Tourney',
    icon: Tourney,
    iconWhite: TourneyWhite,
  },
];
